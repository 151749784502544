import {watchers} from "./watchers.js";
import {register} from "./pages/register.js";
import {data_privacy} from "./pages/data_privacy.js";
import {production} from "./pages/production.js";
import {production_modal} from "./pages/production_modal";
import {prebooking} from "./pages/prebooking.js";
import {prebooking_modal} from "./pages/prebooking_modal.js";
import {prebooking_show} from "./pages/prebooking_show";
import {prebooking_delete_modal} from "./pages/prebooking_delete_modal";
import {booking_request} from "./pages/booking_request.js";
import {dashboard} from "./pages/dashboard.js";
import {search} from "./pages/search.js";
import {calendar} from "./pages/calendar.js";
import {booking} from "./pages/booking.js";
import {user_edit} from "./pages/user_edit.js";
import {blocking_time} from "./pages/blocking_time.js";
import {additional_addresses} from "./pages/additional_addresses.js";
import {todo} from "./pages/todo.js";
import {role} from "./pages/role.js";
import {role_modal} from "./pages/role_modal.js"
import {external} from "./pages/external.js";
import {independent_booker} from "./pages/independent_booker.js";
import {user_modal} from "./pages/user_modal.js";
import {user_show} from "./pages/user_show.js";
import {accept_agbs} from "./pages/accept_agbs.js";
import {bookmark_user_modal} from "./pages/bookmark_user_modal.js";
import {activate} from "./pages/activate";

window.runners = {
    'watchers'               : watchers,
    'register'               : register,
    'data_privacy'           : data_privacy,
    'production'             : production,
    'production_modal'       : production_modal,
    'prebooking'             : prebooking,
    'prebooking_modal'       : prebooking_modal,
    'prebooking_show'        : prebooking_show,
    'prebooking_delete_modal': prebooking_delete_modal,
    'booking_request'        : booking_request,
    'dashboard'              : dashboard,
    'search'                 : search,
    'calendar'               : calendar,
    'booking'                : booking,
    'user_edit'              : user_edit,
    'blocking_time'          : blocking_time,
    'additional_addresses'   : additional_addresses,
    'todo'                   : todo,
    'role'                   : role,
    'role_modal'             : role_modal,
    'external'               : external,
    'independent_booker'     : independent_booker,
    'user_modal'             : user_modal,
    'user_show'              : user_show,
    'accept_agbs'            : accept_agbs,
    'bookmark_user_modal'    : bookmark_user_modal,
    'activate'               : activate,
};

export const runner = {
    /**
     * run an runner
     * @param {String} name
     */
    run: (name) => {
        if (window.runners[name] instanceof Function) {
            window.runners[name]();
        }
        else {
            console.warn(`no runner with name ${name} found!`);
        }
    }
};
