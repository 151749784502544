export function activate() {
    console.log('[activate] running');

    $(document).ready(function () {
        $('#explanation').on('keyup', function () {
            countChars(this);
        });


    });
}

function countChars(val) {
    let len = val.value.length;
    if (len >= 1000) {
        val.value = val.value.substring(0, 1000);
    } else {
        let label_text = 'Begründung der Absage* (' + (1000 - len) + ' Zeichen)';
        $('#explanation_label').text(label_text);
    }
}