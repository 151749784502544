import * as _ from "lodash";
import MicroModal from 'micromodal';
import * as semaphore from "semaphore";

export function prebooking() {
    console.log("[prebooking] running");
    $(document).ready(function () {
        let isChild = window.isChild || false;

        let blockCounter = window.blockCounter || 0;
        let additionalPrebookingCounter = window.additionalPrebookingCounter || 0;
        let newPrebooking = window.newPrebooking === '1';

        let oldSelectProduction = window.oldSelectProduction || 0;

        let existing_prebooking_index = 0;
        let existing_prebooking_counter = 0;

        var xhrQueue = [];

        let sem = require('semaphore')(1);
        let semModal = require('semaphore')(1);

        let modalShowExisting = false;

        var stealthyRequire = require('stealthy-require');

        let MicroModal = stealthyRequire(arguments[5], function () {
            return require('micromodal');
        });

        //let MicroModal = require('micromodal');
        MicroModal.init({
            disableScroll: true,
        });

        $(`#prebooking_save`).on('click', function (e) {
            $('#dynamic-alert-error').hide();
            let $info_text = $('#info_text_area').val();
            if (isChild && (!$info_text || $info_text === '')) {
                e.preventDefault();
                $('#dynamic-alert-error').show();
                $('#dynamic-alert-error-text').html('Bitte gib eine kurze Info zur Produktion an.')
                return;
            }

            if (newPrebooking) {
                e.preventDefault();
                $(`#modal-confirm-save`).modal('open');
                $(`#confirm-save-btn`).off('click').on('click', function () {
                    $(`#prebooking_form`).submit();
                });
            }
        });

        $(document).on('close-modal-bool', function () {
           modalShowExisting = false;
           $(`#modal-holder`).hide();
        });

        $(document).on('close-modal-success', function (event, $prebooking) {
            MicroModal.close('modal-holder');
            $(`#modal-content`).empty();
            $(`#modal-holder`).hide();
            modalShowExisting = false;
            getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking);
            $(`#dynamic-alert-success`).show();
            setTimeout(function () {
                $(`#dynamic-alert-success`).hide();
            }, 3000);

            let $template = $(`#existing-prebooking-template`);
            let $clone = $template.clone(true);
            $clone.attr('id', `existing_prebooking_${existing_prebooking_index}`);
            $clone.find('#existing-prebooking-id')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_id`)
                .val($prebooking['id']);
            $clone.find('#existing-prebooking-production-id')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_production_id`)
                .val($prebooking['production_id']);
            $clone.find('#existing-prebooking-role-id')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_role_id`)
                .val($prebooking['role_id']);
            $clone.find('#existing-prebooking-production')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_production`)
                .html($prebooking['production_name']);
            $clone.find('#existing-prebooking-role')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_role`)
                .html($prebooking['role_name']);
            $clone.find('#existing-prebooking-booker')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_booker`)
                .html($prebooking['booker_name']);
            if ($prebooking['company_name'] && $prebooking['company_name'] !== "") {
                $clone.find('#existing-prebooking-company')
                    .attr('id', `existing_prebooking_${existing_prebooking_index}_company`)
                    .html($prebooking['company_name']);
                $clone.find('#existing-prebooking-company-div')
                    .attr('id', `existing_prebooking_${existing_prebooking_index}_company_div`)
                    .show();
            }
            $clone.find('#existing-prebooking-delete')
                .attr('id', `existing_prebooking_${existing_prebooking_index}_delete`)
                .attr('data-remove-existing-prebooking', existing_prebooking_index)
                .on('click', function () {
                    let $loader = $(`#existing_pb_list_loader`);
                    $loader.show();
                    let id = Number($(this).attr('data-remove-existing-prebooking'));

                    let $prebooking_id = $(`#existing_prebooking_${id}_id`).val();
                    let $production_id = $(`#existing_prebooking_${id}_production_id`).val();
                    let $role_id = $(`#existing_prebooking_${id}_role_id`).val();

                    _.delay(function () {
                        //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                        $.ajax(
                            window.API_BASE + `prebooking/${$prebooking_id}?modal=1`,
                            {
                                method: 'DELETE',
                                success: function (data) {
                                    $(`#existing_prebooking_${id}`).remove();
                                    existing_prebooking_counter--;
                                },
                                error: function () {
                                    console.log('deletion fail');
                                },
                                complete: function () {
                                    $loader.hide();
                                    if (existing_prebooking_counter === 0) {
                                        $(`#existing-prebooking-div`).hide();
                                        $(`#existing-prebooking-header`).hide();
                                    }
                                    getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking);
                                }
                            }
                        )
                    }, 0);
                });

            $(`#existing-prebooking-holder`).append($clone);
            $(`#existing-prebooking-div`).show();
            $(`#existing-prebooking-header`).show();

            existing_prebooking_index++;
            existing_prebooking_counter++;
        });

        getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking);

        $(document).on('click', '[data-remove-block]', function (e) {
            e.preventDefault();
            let id = Number($(this).attr('data-remove-block'));
            $(`#block_${id}`).remove();
            getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking);
        });

        $('#add-block').on("click", () => {
            let $template = $('#block-template');
            let $clone = $template.clone(true);

            $clone.attr('id', `block_${blockCounter}`);
            $clone.find('[data-remove-block]').attr('data-remove-block', blockCounter);
            $clone.find('[name=start]')
                .attr('name', `blocks[${blockCounter}][start]`)
                .attr('id', `start_${blockCounter}`)
                .attr('data-start', blockCounter)
                .addClass('datepicker');
            $clone.find('[for=start]').attr('for', `start_${blockCounter}`);
            $clone.find('[name=end]')
                .attr('name', `blocks[${blockCounter}][end]`)
                .attr('id', `end_${blockCounter}`)
                .addClass('datepicker');
            $clone.find('[for=end]').attr('for', `end_${blockCounter}`);

            $('#block-holder').append($clone);

            blockCounter++;
        });

        $("#production_id").on('change', function () {
            let val = Number($(this).val());

            let $role_id = $('#role_id');
            $role_id.empty();
            let $loader = $('#role_id_loader');
            $loader.show();

            let $emptyOption = $('<option>')
            .val('-1')
             .attr('selected', true)
             .text('Nicht bekannt');
            if (!isChild) {
                $emptyOption = $emptyOption.attr('disabled', true);
                $role_id.append($emptyOption);
            }

            $('#production_id').on('select2:close', function () {
                $('#production_id').off('select2:close');
                $role_id.attr('disabled', 'disabled');

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + 'api/roles',
                        {
                            method:  'GET',
                            data:    {
                                production_id: val
                            },
                            success: function (data) {
                                //$role_id_modal.empty();
                                window.roles = _.sortBy(data, 'displayName');
                                console.log(window.roles);
                                _.chain(window.roles).filter({'production_id': val}).each(function (at) {
                                    let $option = $('<option>').val(at.id).text(at.displayName);
                                    $role_id.append($option);
                                }).value();

                                //$role_id.material_select();
                                $loader.hide();
                                $role_id.removeAttr('disabled');
                            },
                            error:   function () {
                                console.log(arguments);
                            }
                        }
                    );
                }, 0);
            });
        });
        if (oldSelectProduction > 0) {
            $('#production_id').change();
            $('#production').trigger('select2.close');
        }

        $(`#add_existing_prebooking`).on('click', function (event) {
            if (!modalShowExisting) {
                modalShowExisting = true;
                $(`#add_existing_prebooking`).prop('disabled', true);
                $(`#existing_pb_loader`).show();
                let $user_id = document.getElementsByName('user_id')[0].value;
                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `prebooking/-1/edit?modal=1&user_id=${$user_id}`,
                        {
                            method: 'GET',
                            success: function (data) {
                                $(`#modal-content`).empty();
                                $(`#modal-content`).append(data);
                                $(`#modal-holder`).show();
                                //let html = $.parseHTML(data);
                                MicroModal.show('modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading modal');
                            },
                            complete: function () {
                                $(`#existing_pb_loader`).hide();
                                $(`#add_existing_prebooking`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });

        // Time checkboxes, check all/no logic
        let $prebook_inputs = $('#timeslot-table >tbody >tr');
        for (let i = 0; i < $prebook_inputs.length; i++) {
            $('#prebooking_times_' + i + '_all').on('click', function () {
                for (let day = 0; day < 7; day++)
                    $('#prebooking_times_' + day + '_' + i).prop('checked', this.checked);
            });
            for (let day = 0; day < 7; day++) {
                $('#prebooking_times_' + day + '_' + i).on('click', function () {
                    let checked = true;
                    for (let day = 0; day < 7; day++) {
                        checked = checked && $('#prebooking_times_' + day + '_' + i).prop('checked');
                    }
                    $('#prebooking_times_' + i + '_all').prop('checked', checked);
                });
            }
        }

        $(document).arrive('[data-start]', {
            existing: true
        }, function () {

            var id = $(this).attr('data-start');
            let val = $(`[name='blocks[${id}][start]']`).val();
            //noinspection ES6ModulesDependencies
            let start = moment(val, 'DD.MM.YYYY').toDate();
            $(`#end_${id}`).pickadate('set', {
                min: start
            });

            var endTarget = document.getElementById(`end_${id}`);

            $(this).on('change', function () {

                let val = $(`[name='blocks[${id}][start]']`).val();
                //noinspection ES6ModulesDependencies
                let start = moment(val, 'DD.MM.YYYY').toDate();

                $(`#end_${id}`).pickadate('set', {
                    min: start
                });
                _.defer(() => {
                    $(`#end_${id}`).trigger('click');
                });

                let endVal = $(`[name='blocks[${id}][end]']`).val();
                if (endVal !== '' && endVal != null) {
                    let end = moment(endVal, 'DD.MM.YYYY').toDate();
                    getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking);
                }
            });

            $(`#end_${id}`).on('change', function () {

                let val = $(`[name='blocks[${id}][end]']`).val();
                //noinspection ES6ModulesDependencies
                let end = moment(val, 'DD.MM.YYYY').toDate();

                let startVal = $(`[name='blocks[${id}][start]']`).val();
                if (startVal !== '' && startVal != null) {
                    let start = moment(startVal, 'DD.MM.YYYY').toDate();

                    getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking);
                    //getOverlapPrebookingDays(start, end);
                }
            });
        });

        $(document).arrive('.datepicker', {
            existing: true
        }, function () {
            $(this).on('mousedown', function (e) {
                e.preventDefault();
            });
        });

        $('#info_text_area').on('keyup', function () {
            countChars(this);
        });
    });
}

function getOverlappingPrebookingDays(blockCounter, xhrQueue, sem, newPrebooking) {

    if (!newPrebooking) {
        return;
    }

    let $loader = $('#overlap_day_loader');
    let $scroll = $('#overlap_pb_days_scroll');
    let $header = $('#overlap_pb_days_header');

    let send = true;
    let blockDates = [];
    var i;
    for (i = 0; i < blockCounter; i++) {
        let start = document.getElementsByName(`blocks[${i}][start]`) ? $(`[name='blocks[${i}][start]']`).val() : null;
        let end = document.getElementsByName(`blocks[${i}][start]`) ? $(`[name='blocks[${i}][end]']`).val() : null;

        var arrayToPush = [];
        arrayToPush[0] = start;
        arrayToPush[1] = end;

        if (start !== '' && start != null && end !== '' && end != null) {
            blockDates.push(arrayToPush);
        }
    }

    if (blockDates.length > 0) {
        let user_id = document.getElementsByName('user_id')[0].value;

        $header.show();
        $scroll.show();
        $loader.show();

        sem.take(function () {
            _.each(xhrQueue, function (xhr) {
                if (xhr) {
                    xhr.abort();
                }
            });

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                let xhr = $.ajax(
                    window.API_BASE + 'api/overlap_days',
                    {
                        method: 'GET',
                        data: {
                            user_id: user_id,
                            block_dates: blockDates,
                        },
                        success: function (data) {
                            $('.overlap_pb_day').remove();

                            let $template = $('#overlap_pb_day_template');
                            let $holder = $('#overlap_pb_days_holder');

                            let $c = 0;

                            _.each(data, function (day_entry) {
                                let $clone = $template.clone(true);

                                $clone.attr('id', `overlap_pb_day_${$c}`).addClass('overlap_pb_day');
                                $clone.css('display', 'inline-block');

                                $holder.append($clone);

                                document.getElementsByClassName('cDate')[$c].innerHTML = moment(day_entry.p_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
                                document.getElementsByClassName('cDay')[$c].innerHTML = day_entry.p_count_on_day;
                                document.getElementsByClassName('cEvening')[$c].innerHTML = day_entry.p_count_on_evening;

                                $c++;
                            });
                        },
                        complete: function () {
                            sem.take(function () {
                                xhrQueue.shift();
                                if (xhrQueue.length === 0) {
                                    $loader.hide();
                                }
                                sem.leave();
                            });
                        }
                    }
                );
                xhrQueue.push(xhr);
                sem.leave();
            }, 0);
        });

    } else if (blockDates.length === 0) {
        $('.overlap_pb_day').remove();
        $header.hide();
        $scroll.hide();
        $loader.hide();
    }
}

function countChars(val) {
    let len = val.value.length;
    if (len >= 1000) {
        val.value = val.value.substring(0, 1000);
    } else {
        let label_text = 'Infos* (' + (1000 - len) + ' Zeichen)';
        $('#info_text_label').text(label_text);
    }
}
