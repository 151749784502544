import * as _ from "lodash";

export function independent_booker() {
    console.log("[independent_booker] running");
    $(document).ready(function () {
        let productions_count = window.productions_count || 0;
        let full_access = window.full_access || false;

        $('#start').on('change', function () {
            //noinspection ES6ModulesDependencies
            let time = moment($(this).val(), 'dd.mm.YYYY');
            $('#end').val(time).click();
        });

        $('#full_access').on('change', function () {
            let checked = $('#full_access').prop('checked');
            if (checked) {
                $('#check_all_productions').attr('disabled', true);
                for (let i = 0; i < productions_count; i++) {
                    $(`#check_production_${i}`).attr('disabled', true);
                }
            } else {
                $('#check_all_productions').attr('disabled', false);
                for (let i = 0; i < productions_count; i++) {
                    $(`#check_production_${i}`).attr('disabled', false);
                }
            }
        });

        if (full_access) {
            $('#full_access').trigger('change');
        }

        $('#check_all_productions').on('change', function () {
            let checked = $(this).prop('checked');
            if (checked) {
                for (let i = 0; i < productions_count; i++) {
                    $(`#check_production_${i}`).prop('checked', true);
                }
            } else {
                for (let i = 0; i < productions_count; i++) {
                    $(`#check_production_${i}`).prop('checked', false);
                }
            }
        });
    });
}