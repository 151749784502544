import * as _ from "lodash";
import { watchers } from '../watchers';

export function prebooking_modal() {
    console.log('modal attached');

    $(document).ready(function () {
        watchers();

        let child = !!window.child;

        let bookerUsers = JSON.parse(window.bookerUsers);

        let blockCounter_modal = window.blockCounter_modal || 0;
        let user_show = window.user_show;
        let modalShow = false;
        let blockEvent = false;

        $(`#block-template_modal`).hide();

        //let MicroModal = require('micromodal');
        var stealthyRequire = require('stealthy-require');

        let MicroModal = stealthyRequire(arguments[5], function () {
            return require('micromodal');
        });
        MicroModal.init({
            disableScroll: false,
        });

        $(document).on('close-secondary-modal-bool', function () {
            modalShow = false;
            $('#secondary-modal-holder').hide();
        });

        $(document).on('close-secondary-modal-production-success', function (event, $production) {
            MicroModal.close('secondary-modal-holder');
            $(`#secondary-modal-content`).empty();
            $('#secondary-modal-holder').hide();
            modalShow = false;
            $(`#modal-alert-success-div`).show();
            $(`#modal-alert-success-text`).html('Speichern der Produktion erfolgreich.');

            //$('#production_id_modal').select2('destroy');

            //$('#production_id_modal').off('select2:close');

            //$('#production_id_modal').select2();

            var data = {
                id: $production['id'],
                text: $production['best_title']
            };
            var newOption = new Option(data.text, data.id, true, true);
            $(`#production_id_modal`).append(newOption);
            //$(`#production_id_modal`).select2().trigger('select2:close');

            let $role_id_modal = $('#role_id_modal');
            $role_id_modal.empty();
            let $loader = $('#role_id_loader_modal');
            $loader.show();
            let $add_role = $('#add_role_modal');

            let $unknownOption = $('<option>')
                .val('')
                .attr('selected', true)
                .text('Nicht bekannt');
            $role_id_modal.append($unknownOption);

            $role_id_modal.attr('disabled', 'disabled');
            $add_role.attr('disabled', 'disabled');

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + 'api/roles',
                    {
                        method:  'GET',
                        data:    {
                            production_id: $production['id']
                        },
                        success: function (data) {
                            window.roles = _.sortBy(data, 'displayName');
                            _.chain(window.roles).filter({'production_id': $production['id']}).each(function (at) {
                                let $option = $('<option>').val(at.id).text(at.displayName);
                                $role_id_modal.append($option);
                            }).value();

                            // $role_id.material_select();
                            $loader.hide();
                            $role_id_modal.removeAttr('disabled');
                            $add_role.removeAttr('disabled');
                        },
                        error:   function () {
                            console.log(arguments);
                        }
                    }
                );
            }, 0);
        });

        $(document).on('close-secondary-modal-role-success', function (event, $role) {
            MicroModal.close('secondary-modal-holder');
            $(`#secondary-modal-content`).empty();
            $('#secondary-modal-holder').hide();
            modalShow = false;
            $(`#modal-alert-success-div`).show();
            $(`#modal-alert-success-text`).html('Speichern der Rolle erfolgreich.');
            $(`#role_id_modal`).append(`<option selected value="${$role['id']}">${$role['displayName']}</option>`);

        });

        $(document).on('close-secondary-modal-user-success', function (event, $user) {
            MicroModal.close('secondary-modal-holder');
            $(`#secondary-modal-content`).empty();
            $('#secondary-modal-holder').hide();
            modalShow = false;
            $(`#modal-alert-success-div`).show();
            $(`#modal-alert-success-text`).html('Speichern des Aufnahmeleiters erfolgreich.');

            $(`#booker_placeholder_modal`).append(`<option selected value="${$user['id']}">${$user['booker_name']}</option>`);
        });

        $(document).on('click.blockEventPrimary', function (e) {
            if (e.target.hasAttribute('data-custom-close') && !blockEvent) {
                $(`#modal-content`).empty();
                $(document).trigger('close-modal-bool');
                $(document).off('click.blockEventPrimary');
            } else {
                e.stopPropagation();
            }
        });

        $("#production_id_modal").on('change', function () {
            let val = Number($(this).val());

            let $role_id_modal = $('#role_id_modal');
            $role_id_modal.empty();
            let $loader = $('#role_id_loader_modal');
            $loader.show();
            let $add_role = $('#add_role_modal');

            let $emptyOption = $('<option>')
                .val('')
                .attr('selected', true)
                .text('Nicht bekannt');
            $role_id_modal.append($emptyOption);

            $('#production_id_modal').on('select2:close', function () {
                $('#production_id_modal').off('select2:close');
                $role_id_modal.attr('disabled', 'disabled');
                $add_role.attr('disabled', 'disabled');

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + 'api/roles',
                        {
                            method:  'GET',
                            data:    {
                                production_id: val
                            },
                            success: function (data) {
                                //$role_id_modal.empty();
                                window.roles = _.sortBy(data, 'displayName');
                                _.chain(window.roles).filter({'production_id': val}).each(function (at) {
                                    let $option = $('<option>').val(at.id).text(at.displayName);
                                    $role_id_modal.append($option);
                                }).value();

                                // $role_id.material_select();
                                $loader.hide();
                                $role_id_modal.removeAttr('disabled');
                                $add_role.removeAttr('disabled');
                            },
                            error:   function () {
                                console.log(arguments);
                            }
                        }
                    );
                }, 0);
            });
        });

        $('#booker_placeholder_modal').on('select2:close', function () {
            let val = Number($(this).val());
            bookerUsers.forEach( function (bookerUser) {
                if (Number(bookerUser['id']) === val) {
                    if (bookerUser['company_id'] !== null) {
                        $('#company_id_modal').val(bookerUser['company_id']);
                    } else {
                        $('#company_id_modal').val('');
                    }
                    $('#company_id_modal').trigger('change');
                }
            });
        });

        $(document).on('click', '[data-remove-block-modal]', function (e) {
            e.preventDefault();
            let id = Number($(this).attr('data-remove-block-modal'));
            $(`#block_${id}_modal`).remove();
        });

        $(`#add-block_modal`).on('click', () => {
            let $modal_template = $(`#block-template_modal`);
            let $modal_clone = $modal_template.clone(true);

            $modal_clone.attr('id', `block_${blockCounter_modal}_modal`);
            $modal_clone.find('[data-remove-block-modal]').attr('data-remove-block-modal', blockCounter_modal);
            $modal_clone.find('[name=start_modal]')
                .attr('name', `blocks_modal[${blockCounter_modal}][start]`)
                .attr('id', `start_${blockCounter_modal}_modal`)
                .attr('data-start-modal', blockCounter_modal)
                .addClass('datepicker');
            $modal_clone.find('[for=start_modal]').attr('for', `start_${blockCounter_modal}_modal`);
            $modal_clone.find('[name=end_modal]')
                .attr('name', `blocks_modal[${blockCounter_modal}][end]`)
                .attr('id', `end_${blockCounter_modal}_modal`)
                .addClass('datepicker');
            $modal_clone.find('[for=end_modal]').attr('for', `end_${blockCounter_modal}_modal`);
            $modal_clone.show();

            $('#block-holder_modal').append($modal_clone);

            blockCounter_modal++;
        });

        $(`#add_production_modal`).on('click', function () {
            if (!modalShow) {
                modalShow = true;

                $(`#add_production_modal`).prop('disabled', true);
                $(`#add_role_modal`).prop('disabled', true);
                $(`#add_booker_modal`).prop('disabled', true);

                let modal_title_loader = $(`#modal_title_loader`);
                modal_title_loader.show();

                let url_string = window.API_BASE + `production/-1/edit?modal=1`;
                if (user_show) {
                    url_string = url_string + '&user_show=1';
                }

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        url_string,
                        {
                            method: 'GET',
                            success: function (data) {
                                $(`#secondary-modal-content`).empty();
                                $(`#secondary-modal-content`).append(data);
                                $(`#secondary-modal-title-span`).html('Neue Produktion');
                                $('#secondary-modal-holder').show();
                                MicroModal.show('secondary-modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-secondary-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading secondary modal');
                            },
                            complete: function () {
                                modal_title_loader.hide();
                                $(`#add_production_modal`).prop('disabled', false);
                                if ($("#production_id_modal").val() > 0) {
                                    $(`#add_role_modal`).prop('disabled', false);
                                }
                                $(`#add_booker_modal`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });

        $(`#add_role_modal`).on('click', function () {
            if (!modalShow) {
                modalShow = true;

                let $production_id = $(`#production_id_modal`).val();

                if (!$production_id || $production_id === '') {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Wähle bitte eine Produktion für die Rolle aus!');
                    return;
                }

                $(`#add_production_modal`).prop('disabled', true);
                $(`#add_role_modal`).prop('disabled', true);
                $(`#add_booker_modal`).prop('disabled', true);

                let modal_title_loader = $(`#modal_title_loader`);
                modal_title_loader.show();

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `production/${$production_id}/role/-1/edit?modal=1`,
                        {
                            method: 'GET',
                            success: function (data) {
                                $(`#secondary-modal-content`).empty();
                                $(`#secondary-modal-content`).append(data);
                                $(`#secondary-modal-title-span`).html('Neue Rolle');
                                $('#secondary-modal-holder').show();
                                MicroModal.show('secondary-modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-secondary-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading secondary modal');
                            },
                            complete: function () {
                                modal_title_loader.hide();

                                $(`#add_production_modal`).prop('disabled', false);
                                if ($("#production_id_modal").val() > 0) {
                                    $(`#add_role_modal`).prop('disabled', false);
                                }
                                $(`#add_booker_modal`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });

        $(`#add_booker_modal`).on('click', function () {
            if (!modalShow) {
                modalShow = true;

                $(`#add_production_modal`).prop('disabled', true);
                $(`#add_role_modal`).prop('disabled', true);
                $(`#add_booker_modal`).prop('disabled', true);

                let modal_title_loader = $(`#modal_title_loader`);
                modal_title_loader.show();

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `user/create?modal=1`,
                        {
                            method: 'GET',
                            data: {
                                type: 'booker'
                            },
                            success: function (data) {
                                $(`#secondary-modal-content`).empty();
                                $(`#secondary-modal-content`).append(data);
                                $(`#secondary-modal-title-span`).html('Neuer Aufnahmeleiter');
                                $('#secondary-modal-holder').show();
                                MicroModal.show('secondary-modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-secondary-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading secondary modal');
                            },
                            complete: function () {
                                modal_title_loader.hide();

                                $(`#add_production_modal`).prop('disabled', false);
                                if ($("#production_id_modal").val() > 0) {
                                    $(`#add_role_modal`).prop('disabled', false);
                                }
                                $(`#add_booker_modal`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });

        // Time checkboxes, check all/no logic
        let $prebook_inputs = $('#timeslot-table_modal >tbody >tr');
        for (let i = 0; i < $prebook_inputs.length; i++) {
            $('#prebooking_times_' + i + '_all_modal').on('click', function () {
                for (let day = 0; day < 7; day++)
                    $('#prebooking_times_' + day + '_' + i + '_modal').prop('checked', this.checked);
            });

            for (let day = 0; day < 7; day++) {
                $('#prebooking_times_' + day + '_' + i + '_modal').on('click', function () {
                    let checked = true;
                    for (let day = 0; day < 7; day++) {
                        checked = checked && $('#prebooking_times_' + day + '_' + i + '_modal').prop('checked');
                    }
                    $('#prebooking_times_' + i + '_all_modal').prop('checked', checked);
                });
            }
        }

        $(document).arrive('[data-start-modal]', {
            existing: true
        }, function () {

            var id = $(this).attr('data-start-modal');
            let val = $(`[name='blocks_modal[${id}][start]']`).val();
            //noinspection ES6ModulesDependencies
            let start = moment(val, 'DD.MM.YYYY').toDate();
            $(`#end_${id}_modal`).pickadate('set', {
                min: start
            });

            $(this).on('change', function () {
                let val = $(`[name='blocks_modal[${id}][start]']`).val();
                //noinspection ES6ModulesDependencies
                let start = moment(val, 'DD.MM.YYYY').toDate();

                $(`#end_${id}_modal`).pickadate('set', {
                    min: start
                });
                _.defer(() => {
                    $(`#end_${id}_modal`).trigger('click');
                });
            });
        });

        $(document).arrive('.datepicker', {
            existing: true
        }, function () {
            $(this).on('mousedown', function (e) {
                e.preventDefault();
            });
        });

        $(`#save_modal`).on('click', function () {

            let save_button = $(`#save_modal`);

            $(`#modal-alert-div`).hide();
            $(`#modal-alert-text`).html('');

            let $production_id = $(`#production_id_modal`).val();
            if (!$production_id || $production_id === '') {
                // $(`#modal-alert-div`).show();
                // $(`#modal-alert-text`).html('Wähle bitte eine Produktion aus!');
                // $(`#save_loader_modal`).hide();
                // return;
            }


            let $role_id = $(`#role_id_modal`).val();
            if (!$role_id || $role_id === '') {
                $role_id = -1;

                /*$(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Wähle bitte eine Rolle aus!');
                $(`#save_loader_modal`).hide();
                return;*/
            }

            let $booker_id = $(`#booker_placeholder_modal`).val();
            if (!$booker_id || $booker_id === '') {
                $(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Wähle bitte einen Aufnahmeleiter aus!');
                $(`#save_loader_modal`).hide();
                return;
            }

            let $company_id = $('#company_id_modal').val();
            if (!$company_id || $company_id === '') {
                $(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Wähle bitte ein Unternehmen aus!');
                $(`#save_loader_modal`).hide();
                return;
            }

            let $info_text = $('#info_text_area_modal').val();
            if (child && (!$info_text || $info_text === '')) {
                $(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Bitte gib eine kurze Info zur Produktion an!');
                $(`#save_loader_modal`).hide();
                return;
            }

            let $user_id = document.getElementsByName('user_id')[0].value;

            let $blocks_modal = [];
            let $i;
            for ($i = 0; $i < blockCounter_modal; $i++) {
                let startVal = $(`[name='blocks_modal[${$i}][start]']`).val();
                let endVal = $(`[name='blocks_modal[${$i}][end]']`).val();
                if ((startVal !== '' && startVal !== null) && (endVal !== '' && endVal !== null)) { // null check maybe with single equal sign
                    $blocks_modal[$i] = {
                        'start' : startVal,
                        'end' : endVal
                    };
                }
                else {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Nicht alle Blöcke sind mit Daten versehen!');
                    $(`#save_loader_modal`).hide();
                    return;
                }
            }
            if (!($blocks_modal.length > 0)) {
                $(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Gib bitte mindestens einen Block an!');
                $(`#save_loader_modal`).hide();
                return;
            }

            save_button.prop('disabled', true);
            $(`#save_loader_modal`).show();
            blockEvent = true;

            let times_modal = [];

            let $prebook_inputs = $('#timeslot-table >tbody >tr');
            for (let i = 0; i < 7; i++) {
                let dayChecked = $(`#prebooking_times_` + i + `_` + 0 + `_modal`).prop('checked');
                let eveningChecked = $(`#prebooking_times_` + i + `_` + 1 + `_modal`).prop('checked');

                if (dayChecked != null && dayChecked && eveningChecked != null && eveningChecked) {
                    times_modal[i] = {
                        0: 'on',
                        1: 'on'
                    }
                } else if (dayChecked) {
                    times_modal[i] = {
                        0: 'on'
                    }
                } else if (eveningChecked) {
                    times_modal[i] = {
                        1: 'on'
                    }
                } else {
                    times_modal[i] = {};
                }
            }

            let url = window.API_BASE + `prebooking/modal`;

            if (user_show) {
                url = url + '?user_show=1';
            }

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    url,
                    {
                        method: 'POST',
                        data: {
                            production_id: $production_id,
                            role_id: $role_id,
                            user_id: $user_id,
                            booker_id: $booker_id,
                            info_text: $info_text,
                            blocks: $blocks_modal,
                            prebooking_times: times_modal,
                            company_id: $company_id
                        },
                        success: function (data) {
                            $(document).trigger('close-modal-success', [data])
                        },
                        error: function () {
                            $(`#modal-alert-div`).show();
                            $(`#modal-alert-text`).html('Speichern fehlgeschlagen.');
                        },
                        complete: function () {
                            save_button.prop('disabled', false);
                            $(`#save_loader_modal`).hide();
                            blockEvent = false;
                        }
                    }
                );
            }, 0);
        });

        $('#info_text_area_modal').on('keyup', function () {
            countChars(this);
        });
    });
}

function countChars(val) {
    let len = val.value.length;
    if (len >= 1000) {
        val.value = val.value.substring(0, 1000);
    } else {
        let label_text = 'Infos* (' + (1000 - len) + ' Zeichen)';
        $('#info_text_label_modal').text(label_text);
    }
}