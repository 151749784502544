import * as _ from "lodash";
import moment from "moment";

export function booking_request() {
    console.log("[booking_request] running");
    $(document).ready(function () {
        window.days = JSON.parse(window.days);
        window.days = _.map(window.days, function (d) {
            //noinspection ES6ModulesDependencies
            d.parsed = moment(d.raw.date);
            d.key = d.parsed.format("YYYY-MM-DD HH:mm:ss");
            return d;
        });

        //noinspection ES6ModulesDependencies
        window.end_time = moment(window.end_time, 'HH:mm:ss');

        window.directors = JSON.parse(window.directors);
        window.directors = _.map(window.directors, 'id');
        window.companies = JSON.parse(window.companies);
        window.companies = _.map(window.companies, 'id');
        window.ateliers = JSON.parse(window.ateliers);

        window.roles = JSON.parse(window.roles);
        window.role_scopes = JSON.parse(window.role_scopes);

        $('#modal-confirm').modal();

        $('form').on('submit', function (e) {
            if (window.confirmCallDay || window.confirmCallTime || window.confirmCallDirector ||
                window.confirmCallCompany) {
                e.preventDefault();
                var form = this;
                $('#modal-confirm').modal("open");
                $('#confirm-btn').off('click').on('click', function () {
                    $('#save_button').prop('disabled', true);
                    $('#confirm-btn').prop('disabled', true);
                    form.submit();
                });
            }
            else {
                $('#save_button').prop('disabled', true);
            }
        });

        $('#start, #end').on('mousedown', function (e) {
            e.preventDefault();
        });

        $('#start, #end').on('change', function (e) {
            let _what = e.target.id;
            let _val = $(this).val();

            //noinspection ES6ModulesDependencies
            let start = moment($('#start').val(), 'HH:mm');
            //noinspection ES6ModulesDependencies,JSJQueryEfficiency
            let end = moment($('#end').val(), 'HH:mm');

            //noinspection ES6ModulesDependencies
            let diff = moment.duration(end.diff(start));
            let diffStr = moment.utc(diff.asMilliseconds()).format('HH:mm');
            if (diffStr.length === 2) {
                diffStr = `00:${diffStr}`;
            }

            $('#day').trigger('change');

            $('#length').val(diffStr);

            if ($(this).attr("id") === "start") {
                $("#end").trigger("click");
            }
        });

        $('#length').on('change', function () {
            //noinspection ES6ModulesDependencies
            let start = moment($('#start').val(), 'HH:mm');
            //noinspection ES6ModulesDependencies,JSJQueryEfficiency
            let end = moment($('#end').val(), 'HH:mm');
            //noinspection ES6ModulesDependencies
            let maxDiff = moment.duration(end.diff(start));

            //noinspection ES6ModulesDependencies
            let diff = moment.duration($(this).val());
            if (diff._milliseconds > maxDiff._milliseconds) {
                let diffStr = maxDiff.format('HH:mm');
                if (diffStr.length == 2) {
                    diffStr = `00:${diffStr}`;
                }

                $(this).val(diffStr);
            }
        });

        var checkTimeWarning = function () {
            if (window.confirmCallDay || window.confirmCallTime) {
                $("#alert-time").show();
            }
            else {
                $("#alert-time").hide();
            }
        };

        $('#day').on('change', function () {
            let val = $(this).val();
            let day = _.first(_.filter(window.days, {key: val}));

            let time_blocked_by_times = false;

            if (typeof day.blocked_start_time !== 'undefined' && typeof day.blocked_end_time !== 'undefined') {

                let blocked_start = moment(day.blocked_start_time, 'HH:mm:ss');
                let blocked_end = moment(day.blocked_end_time, 'HH:mm:ss');

                //noinspection ES6ModulesDependencies
                let start = moment($('#start').val(), 'HH:mm');
                //noinspection ES6ModulesDependencies,JSJQueryEfficiency
                let end = moment($('#end').val(), 'HH:mm');

                if (start.isSameOrBefore(blocked_end) && end.isSameOrAfter(blocked_start)) {
                    time_blocked_by_times = true;
                }
            }

            window.confirmCallDay = day.status === 'red' || time_blocked_by_times;

            if (day.need_regional_authorities_registration) {
                $("#alert-regional-authorities-registration").removeClass("slide-in");
            }
            else {
                $("#alert-regional-authorities-registration").addClass("slide-in");
            }

            //if (moment().diff(day.parsed, 'days'))
            let dayObject = day.parsed.toObject();
            let nowObject = moment().toObject();
            if (dayObject.date === nowObject.date) {
                let end_time = moment(day.end_time.date);
                let difference = moment.duration(end_time.diff(moment())).asMinutes();
                if (difference < 0) {
                    $("#alert-end-time").show();
                } else {
                    $("#alert-end-time").hide();
                }
            } else {
                $("#alert-end-time").hide();
            }

            checkTimeWarning();
        }).trigger("change");

        $("#director_id").on('change', function () {
            let val = Number($(this).val());
            console.log(`val: ${val}`);
            if (_.indexOf(window.directors, val) >= 0) {
                $("#alert-director").show();
                window.confirmCallDirector = true;
            }
            else {
                $("#alert-director").hide();
                window.confirmCallDirector = false;
            }
        });

        $("#company_id").on('change', function () {
            let val = Number($(this).val());
            console.log(`val: ${val}`);
            if (_.indexOf(window.companies, val) >= 0) {
                $("#alert-company").show();
                window.confirmCallCompany = true;
            }
            else {
                $("#alert-company").hide();
                window.confirmCallCompany = false;
            }
        });

        $("#studio_id").on('change', function () {
            let val = Number($(this).val());
            console.log(`val: ${val}`);

            var $atelier_id = $('#atelier_id');
            $atelier_id.html("");

            let $emptyOption = $('<option>')
                .val('')
                .prop('disabled', true)
                .attr('selected', true)
                .text('Bitte auswählen');
            $atelier_id.append($emptyOption);

            _.chain(window.ateliers).filter({'studio_id': val}).each(function (at) {
                let $option = $('<option>').val(at.id).text(at.name);
                $atelier_id.append($option);
            }).value();

            $atelier_id.material_select();
        });

        $('#role_placeholder').on('change', function () {
            let role_option = $(this).children("option:selected");
            let role_id = parseInt(role_option.val());
            if (role_id !== -1) {
                let role = window.roles.find(function (r) {
                    return r.id === role_id;
                });
                let role_scope_id = role.role_scope_id;
                let role_scope = window.role_scopes.find(function (r_s) {
                    return r_s.id === role_scope_id;
                });
                $('#production_type').val(role_scope.title);
                $('#production_type').material_select();
            }
            else {
                $('#production_type').children("option:selected").prop('selected', false);
            }
        }).trigger('change');
    });
}
