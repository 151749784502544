// require('../../../node_modules/babel-es6-polyfill/browser-polyfill');

import {runner} from './runner.js';
// require('./runner.js');
window.runner = runner;

$(document).ready(() => {
    runner.run('watchers');

    if (parseInt(window.notification_count, 10) > 0) {
        document.title = `(${window.notification_count}) ${document.title}`;
    }
});
