import * as _ from "lodash";
import MicroModal from 'micromodal';
import { watchers } from '../watchers';

export function user_show() {
    console.log('[user_show] running');

    $(document).ready(function () {
        let modalShowExisting = false;

        var stealthyRequire = require('stealthy-require');

        let MicroModal = stealthyRequire(arguments[5], function () {
            return require('micromodal');
        });

        MicroModal.init({
            disableScroll: true,
        });

        $(document).on('close-modal-bool', function () {
            modalShowExisting = false;
            $(`#modal-holder`).hide();
        });

        $(document).on('close-modal-success', function (event, data) {
            MicroModal.close('modal-holder');
            $(`#modal-content`).empty();
            $(`#modal-holder`).hide();
            modalShowExisting = false;
            $(`#dynamic-alert-success`).show();
            setTimeout(function () {
                $(`#dynamic-alert-success`).hide();
            }, 3000);
        });

        $('#add_prebooking').prop('disabled', false);

        $('#add_prebooking').on('click', function (event) {
            if (!modalShowExisting) {
                modalShowExisting = true;
                $(`#add_prebooking`).prop('disabled', true);
                $(`#bookmark_user`).prop('disabled', true);
                $(`#add_prebooking_loader`).show();
                let $user_id = document.getElementsByName('user_id')[0].value;
                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `prebooking/-1/edit?modal=1&user_id=${$user_id}&user_show=1`,
                        {
                            method: 'GET',
                            success: function (data) {
                                $(`#modal-content`).empty();
                                $(`#modal-content`).append(data);
                                $(`#modal-holder`).show();
                                $('#modal-title-span').text('Neue Voranmeldung');
                                //let html = $.parseHTML(data);
                                MicroModal.show('modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading modal');
                            },
                            complete: function () {
                                $(`#add_prebooking_loader`).hide();
                                $(`#add_prebooking`).prop('disabled', false);
                                $(`#bookmark_user`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });

        $('#bookmark_user').prop('disabled', false);

        $('#bookmark_user').on('click', function () {
            if (!modalShowExisting) {
                modalShowExisting = true;
                $(`#add_prebooking`).prop('disabled', true);
                $(`#bookmark_user`).prop('disabled', true);
                $(`#bookmark_user_loader`).show();
                let $user_id = document.getElementsByName('user_id')[0].value;
                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `user/${$user_id}/bookmark/modal?user_show=1`,
                        {
                            method: 'GET',
                            success: function (data) {
                                $(`#modal-content`).empty();
                                $(`#modal-content`).append(data);
                                $(`#modal-holder`).show();
                                $('#modal-title-span').text('Neue Vormerkung für Rolle');
                                //let html = $.parseHTML(data);
                                MicroModal.show('modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading modal');
                            },
                            complete: function () {
                                $(`#bookmark_user_loader`).hide();
                                $(`#add_prebooking`).prop('disabled', false);
                                $(`#bookmark_user`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });
    });
}