import * as _ from "lodash";

export function dashboard() {
    console.log("[dashboard] running");
    $(document).ready(function () {
        $('.toggle-view').on('click', (e) => {
            let $this  = $(e.currentTarget);
            let target = $this.attr('href');

            $(target).removeClass('hidden');
            $('.section').not(target).addClass('hidden');
            $('.toggle-view').parent().removeClass('active');
            $this.parent().addClass('active');

            let height = $(target).outerHeight(true);
            height += $('#section_switcher').outerHeight(true);
            $('#dashboard').children().css('height', `${height}px`);

            _.defer(function () {
                window.scrollTo(0, 0);
                $("body").scrollTop(0);
            });
        });

        $('[data-remove="user"]').on('click', (e) => {
            e.preventDefault();
            let ref = $(e.currentTarget).attr('href');
            $('#modal-confirm-remove-user').modal('open');
            $('#confirm-remove-user-btn').off('click').on('click', () => {
                location.href = ref;
            });
        });

        $('[data-remove="production"]').on('click', (e) => {
            e.preventDefault();
            let ref = $(e.currentTarget).attr('href');
            $('#modal-confirm-remove-production').modal('open');
            $('#confirm-remove-production-btn').off('click').on('click', ()=> {
                location.href = ref;
            })
        });

        if (window.location.hash.length) {
            console.log(`[dashboard] clicking ${window.location.hash}`);
            $(`a[href="${window.location.hash}"]`).trigger('click');
        } else if (window.from_show_productions) {
            $(`a[href="#productions"]`).trigger('click');
        } else if (window.is_dispatcher) {
            console.log(`[dashboard] dispatcher; clicking #voice_actors`);
            $(`a[href="#voice_actors"]`).trigger('click');
        }
    });
}
