import * as _ from "lodash";

export function search() {
    console.log("[search] running");
    $(document).ready(function () {
        _.defer(function () {
            var slider = $('#age-range')[0].noUiSlider;
            var $min   = $('#age-range-min');
            var $max   = $('#age-range-max');

            slider.on('update', function (rawNew, un, parsedNew) {
                $min.val(parsedNew[0]);
                $max.val(parsedNew[1]);
            });

            $($min).add($max).on('change', function () {
                slider.set([$min.val(), $max.val()]);
            });

            $('#voice_profiles').parent().children('.select2').css('width', '100%');
        });
    });
}
