export function data_privacy() {
    console.log("[data_privacy] running");
    $(document).ready(function () {
        $('#flag_show_working_times').on('change', (e) => {
            let checked = $(e.target).is(':checked');
            console.log(`checked: ${checked}`);

            if (checked) {
                $("#allow-anyways").addClass("slide-in")
            }
            else {
                $("#allow-anyways").removeClass("slide-in")
            }
        }).trigger('change');
    });
}
