import * as _ from 'lodash';
import { watchers } from "../watchers";

export function prebooking_show() {
    $(document).ready(function () {

        let user_id = window.user_id || 0;

        let prebooking_id = window.prebooking_id || 0;

        let production_id = window.production_id || 0;

        let role_id = window.role_id || 0;

        let modalShow = false;

        var stealthyRequire = require('stealthy-require');

        let MicroModal = stealthyRequire(arguments[5], function () {
            return require('micromodal');
        });

        MicroModal.init({
            disableScroll: false,
        });

        $(`#prebooking_delete`).on('click', function (e) {
            e.preventDefault();
            $(`#modal-confirm-delete`).modal('open');
            $(`#confirm-delete-btn`).off('click').on('click', function () {
                $(`#prebooking_show_form`).submit();
            });
        });

        $(document).on('close-modal-bool', function () {
            modalShow = false;
            $(`#modal-holder`).hide();
        });

        $(document).on('close-modal-success', function (event, $prebooking_blocks) {
            MicroModal.close('modal-holder');
            $(`#modal-content`).empty();
            $(`#modal-holder`).hide();
            modalShow = false;
            $(`#dynamic-alert-success`).show();
            setTimeout(function () {
                $(`#dynamic-alert-success`).hide();
            }, 3000);

            $prebooking_blocks.sort(function (first, second) {
                return new Date(first['start']['date']) - new Date(second['start']['date']);
            });

            $(`#prebooking_blocks_list`).empty();

            for (let i = 0; i < $prebooking_blocks.length; i++) {
                let start = moment($prebooking_blocks[i]['start']['date']).format('DD.MM.YYYY');
                let end = moment($prebooking_blocks[i]['end']['date']).format('DD.MM.YYYY');
                $(`#prebooking_blocks_list`).append(`<li class="collection-item">${start} bis ${end}</li>`)
            }
        });

        $(`#delete-at`).on('click', function () {
            showDestroyModal(1);
        });

        $(`#delete-from`).on('click', function () {
            showDestroyModal(2);
        });

        $(`#delete-from-to`).on('click', function () {
            showDestroyModal(3);
        });

        function showDestroyModal(delete_mode) {
            if (!modalShow) {
                $(`#delete-at`).prop('disabled', true);
                $(`#delete-from`).prop('disabled', true);
                $(`#delete-from-to`).prop('disabled', true);

                switch (delete_mode) {
                    case 1:
                        $(`#delete-at-loader`).show();
                        break;
                    case 2:
                        $(`#delete-from-loader`).show();
                        break;
                    case 3:
                        $(`#delete-from-to-loader`).show();
                        break;
                    default:
                }

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `prebooking/show/delete_modal`,
                        {
                            method: 'GET',
                            data: {
                                'delete_mode': delete_mode,
                                'prebooking_id': prebooking_id,
                                'user_id': user_id,
                            },
                            success: function (data) {
                                $(`#modal-content`).empty();
                                $(`#modal-content`).append(data);
                                $(`#modal-holder`).show();
                                MicroModal.show('modal-holder', {
                                    debugMode: false,
                                    disableScroll: true,
                                    closeTrigger: 'data-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error: function () {
                                console.log('Error loading modal');
                            },
                            complete: function () {
                                $(`#delete-at-loader`).hide();
                                $(`#delete-from-loader`).hide();
                                $(`#delete-from-to-loader`).hide();

                                $(`#delete-at`).prop('disabled', false);
                                $(`#delete-from`).prop('disabled', false);
                                $(`#delete-from-to`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        }
    });
}