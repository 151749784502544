import * as _ from "lodash";

export function blocking_time() {
    console.log("[blocking_time] running");
    $(document).ready(function () {

        $('#start_time, #end_time').on('mousedown', function (e) {
            e.preventDefault();
        });

        $('#all_day').on('change', () => {
            let checked = $('#all_day').is(':checked');
            if (checked) {
                $('#start_time').val('00:00').attr('disabled', 'disabled');
                $('#end_time').val('00:00').attr('disabled', 'disabled');
                $('#start_time_label').addClass('active');
                $('#end_time_label').addClass('active');
            }
            else {
                $('#start_time').removeAttr('disabled');
                $('#end_time').removeAttr('disabled');
            }
        });
        if ($('#start_time').val() === $('#end_time').val()) {
            $('#all_day').prop('checked', true).trigger('change');
        }

        $('[name="start_date"]').on('change', () => {
            _.defer(() => {
                let $start = $('[name="start_date"]');

                //noinspection ES6ModulesDependencies
                let start = moment($start.val(), 'YYYY-MM-DD');

                $('#end_date').pickadate('set', {
                    min: start.toDate()
                });
            });
        }).trigger('click');

        $('[name="end_date"]').on('change', () => {
            _.defer(() => {
                let $start = $('[name="start_date"]');
                let $end   = $('[name="end_date"]');

                //noinspection ES6ModulesDependencies
                let start = moment($start.val(), 'YYYY-MM-DD');
                //noinspection ES6ModulesDependencies
                let end   = moment($end.val(), 'YYYY-MM-DD');
                let diff  = end.diff(start, 'days');

                if (diff <= 8) { // if only one week
                    $("[id^=occ]").prop('checked', false);

                    //noinspection ES6ModulesDependencies
                    let current = moment(start);
                    do {
                        let weekday = current.weekday();
                        $(`#occ_${weekday}`).prop('checked', true);
                        current.add(1, 'day');
                    }
                    while (end.diff(current, 'days') >= 0);
                }
            });
        });
    });
}
