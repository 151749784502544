import * as _ from "lodash";

export function additional_addresses() {
    console.log("[additional_addresses] running");
    $(document).ready(function () {
        let counter = -2;

        $(document).on('click', '[data-remove-address]', function (e) {
            e.preventDefault();
            let id = Number($(this).attr('data-remove-address'));
            $(`#address_${id}`).remove();
        });

        $('#add-address').on("click", (e) => {
            e.preventDefault();
            let $template = $('#address-template');
            let $clone    = $template.clone(true);

            $clone
                .attr('id', `address_${counter}`)
                .css('display', 'block');

            $clone.find('[data-remove-address]').attr('data-remove-address', counter);
            $clone.find('input,label').each(function () {
                let $this = $(this);
                _.each([
                    'id',
                    'name',
                    'for',
                ], (a) => {
                    let _attr = $this.attr(a);
                    _attr     = _.replace(_attr, '-1', counter);
                    $this.attr(a, _attr);
                });
            });

            counter--;

            $clone.insertBefore($template);
        });
    });
}
