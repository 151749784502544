export function todo() {
    console.log("[todo] running");
    $(document).ready(function () {
        let starts = {}, ends = {};

        $('[data-start]').on('click', function () {
            let br_id     = $(this).data('br-id');
            starts[br_id] = $(this).data('start');
            console.log(`[todo] start set for br #${br_id}: ${starts[br_id]}`);

            let list_id = $(this).closest('ul').attr('id');
            let elem    = $(`[data-activates='${list_id}']`);
            elem.text(starts[br_id]);
        });

        $('[data-end]').on('click', function () {
            let br_id   = $(this).data('br-id');
            ends[br_id] = $(this).data('end');
            console.log(`[todo] end set for br #${br_id}: ${ends[br_id]}`);

            let list_id = $(this).closest('ul').attr('id');
            let elem    = $(`[data-activates='${list_id}']`);
            elem.text(ends[br_id]);
        });

        $('[data-submit-br]').on('click', function () {
            let br_id = $(this).data('submit-br');
            let booker_id = $(this).data('submit-br-booker');
            let hash = $(this).data('submit-br-hash');
            console.log(`[todo] sending bounded agreement for br #${br_id}`);

            if (!starts[br_id] && !ends[br_id]) {
                return;
            }

            let uri = `/booking_requests/${br_id}/booker/${booker_id}/confirm_bounded`;
            if (starts[br_id] && !ends[br_id]) {
                uri += `/start/${starts[br_id]}`;
            }
            else if (!starts[br_id] && ends[br_id]) {
                uri += `/end/${ends[br_id]}`;
            }
            else {
                uri += `/bounds/${starts[br_id]}/${ends[br_id]}`;
            }

            uri += `?hash=${hash}`;

            console.log(`[todo] going to ${uri}`);
            location.href = uri;
        });

        $('[data-confirm-br]').on('click', function () {
            let br_id = $(this).data('confirm-br');
            let hash = $(this).data('confirm-br-hash');
            console.log(`[todo] confirming br #${br_id}`);

            if (!starts[br_id] || !ends[br_id]) {
                return;
            }

            let uri = `booking_requests/${br_id}/confirm_booking_request/${starts[br_id]}/${ends[br_id]}?hash=${hash}`;

            console.log(`[todo] going to ${uri}`);
            location.href = uri;
        });
    });
}
