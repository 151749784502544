import * as _ from "lodash";

export function watchers() {
    console.log("[watchers] running");
    $(document).ready(function () {
        $('[data-initialize="materialize-select"]').each(function () {
            let $this = $(this);

            let normalizeWhiteSpaces = $this.data('normalize-whitespaces');
            if (normalizeWhiteSpaces) {
                $this.children('option').each(function () {
                    $(this).text($(this).text().replace(/\s+/g, ' '));
                });
            }

            $this.material_select();
        });
        $("select[required]").css({display: "inline", height: 0, padding: 0, width: 0, border: 0});
        $('[data-initialize="select2-tokens"]').each(function () {
            let $this = $(this);
            _.each($this.children(), function (el) {
                $(el).text($(el).text().trim());
            });

            $this.select2({
                tags:            true,
                tokenSeparators: [' ', ',']
            });
            $this.on('select2:open', function () {
                console.log('[setup] tags opening');
                console.log($this);
            });

            $this.on('select2:open', function () {
                $('.select2-dropdown').addClass('opening tokens');
            }).on('select2:closing', function (e) {
                // let $dropdown = $('.select2-dropdown');
                //noinspection JSUnresolvedVariable
                if ($this.hasClass('opening')) {
                    e.preventDefault();
                    $this.removeClass('opening tokens');
                    _.delay(function () {
                        $this.select2('close');
                    }, 300);
                }
                else {
                    $this.removeClass('opening tokens');
                }
            });
        });
        $('[data-initialize="select2"]').each(function () {
            let $this = $(this);

            let normalizeWhiteSpaces = $this.data('normalize-whitespaces');
            if (normalizeWhiteSpaces) {
                $this.children('option').each(function () {
                    $(this).text($(this).text().replace(/\s+/g, ' '));
                });
            }

            let placeholder = $this.data('placeholder') || undefined;
            let min         = Number($this.data('min') || 1);

            let render_image_class = $this.data('render-image') || undefined;

            // TODO render static option
            // function renderText(data) {
            //     let text = data.text;
            //     if (render_image_class) {
            //         text = createDivToRenderString(render_image_class, d) + d.displayName;
            //     }
            // }

            let options = {
                tags:        false,
                placeholder: placeholder,
                escapeMarkup: function (markup) {
                    return markup;
                },
                templateResult: function (data) {
                    return data.text;
                },
                templateSelection: function (data) {
                    return data.text;
                },
            };

            let ajax_uri = $this.data('ajax') || undefined;
            if (ajax_uri) {
                //noinspection JSUnresolvedVariable
                options.ajax               = {
                    url:            window.API_BASE + ajax_uri,
                    dataType:       'json',
                    delay:          250,
                    processResults: function (data) {
                        let res = _.chain(data)
                                    .map(function (d) {
                                        let text = d.displayName;
                                        if (render_image_class) {
                                            text = createDivToRenderString(render_image_class, d) + d.displayName;
                                        }
                                        return {
                                            id:   d.id,
                                            text: text,
                                            title: d.displayName
                                        }
                                    })
                                   .sortBy('text')
                                   .value();
                        return {
                            results:    res,
                            pagination: {
                                more: false,
                            },
                        }
                    },
                    cache:          false,
                };
                options.minimumInputLength = min;
            }

            $this.select2(options);

            $this.on('select2:open', function () {
                /*_.defer(() => {
                 $('.select2-dropdown').addClass('opening');
                 $('.select2-search__field').attr('placeholder', 'Suchen...');
                 });*/
                $('.select2-dropdown').addClass('opening');
                $('.select2-search__field').attr('placeholder', 'Suchen...');
            }).on('select2:closing', function (e) {
                let $dropdown = $('.select2-dropdown');
                //noinspection JSUnresolvedVariable
                if ($dropdown.hasClass('opening')) {
                    e.preventDefault();
                    $dropdown.removeClass('opening');
                    $dropdown.off('transitionend').on('transitionend', function () {
                        $(e.target).select2('close');
                        $dropdown.off('transitionend');
                    });
                }
            });
        });
        $('[data-initialize="starrating"]').each(function () {
            let $this  = $(this);
            let rating = Number($this.data("value"));
            let name   = $this.data("name") || 'child_data[experience_level]';
            //noinspection JSUnresolvedFunction
            $this.addRating({
                icon:      'star',
                max:       3,
                half:      false,
                fieldName: name,
                fieldId:   'experience_level'
            });
            $this.find('.material-icons').on('click', function (e) {
                let _r = $(this).data('rating');
                if (_r == rating) {
                    e.preventDefault();
                    //noinspection JSUnusedAssignment
                    rating = 0;
                    $this.setRating(0);
                    return;
                }
                rating = _r;
            });
            if (rating > 0) {
                //noinspection JSUnresolvedFunction
                $this.setRating(rating);
            }
        });
        $('[data-initialize="tablesorter"]').each(function () {
            let $this    = $(this);
            let sortList = $this.data("sort-list") || [];
            $this.tablesorter({
                sortList: sortList
            });
        });
        $('[data-initialize="pushpin"]').each(function () {
            let $this  = $(this);
            let top    = $this.offset().top;
            let width  = $this.outerWidth();
            let height = $this.outerHeight();

            $this.pushpin({
                top:    0,
                offset: top
            });
            $this.css('width', width).css('z-index', 1000);
            let pt = parseInt($this.parent().css('padding-top')) + height;
            $this.parent().css('padding-top', pt);
        });
        $('[data-initialize="noui-slider"]').each(function () {
            let $this = $(this);
            noUiSlider.create($this[0], {
                start:   $this.data('start'),
                connect: true,
                step:    1,
                range:   {
                    'min': $this.data('min'),
                    'max': $this.data('max')
                },
                format:  wNumb({
                    decimals: 0
                })
            });
        });
        $('.modal').modal();
        $('.swipebox').swipebox();
        $(document).arrive('.datepicker', {
            existing: true
        }, function () {
            let opts = {
                closeOnSelect: true,
                closeOnClear:  true,
                selectMonths:  true,
                selectYears:   100,
                today:         false,
                formatSubmit:  'dd.mm.yyyy',
                format:        'dd.mm.yyyy',
                hiddenName:    true
            };

            if ($(this).attr('data-min')) {
                opts.min = JSON.parse($(this).attr('data-min'));
            }
            if ($(this).attr('data-max')) {
                opts.max = JSON.parse($(this).attr('data-max'));
            }
            if ($(this).attr('data-submit-format')) {
                opts.formatSubmit = $(this).attr('data-submit-format');
            }
            let picker = $(this).pickadate(opts);
            _.defer(Materialize.updateTextFields);

            $(this).on('change', function () {
                _.defer(function () {
                    $(picker).pickadate('close');
                });
            })
        });

        /*$(document).on('click', '.picker__day', function () {
         console.log('click');
         var closeBtn = $(this).closest('.picker__box').find('.picker__close');
         _.defer(closeBtn.trigger, 'click');
         });*/

        $(document).arrive('.timepicker', {
            existing: true,
        }, function () {
            let $this = $(this);
            $this.pickatime({
                twelvehour:   false,
                autoclose:    true,
                formatSubmit: 'HH:ii',
                hiddenName:   true,
                format:       'HH:ii',
                donetext:     'Fertig',
                disable:      [
                    [9, 30]
                ],
                afterShow:    () => {
                    let label = $this.data('label');
                    if (label) {
                        $('.clockpicker-am-pm-block')
                            .html('')
                            .addClass('animated')
                            .addClass('zoomIn')
                            .append(
                                $("<span>")
                                    .addClass('timepicker-label')
                                    .text(label)
                            );
                    }

                    let noFinish = Boolean($this.data('nofinish'));
                    if (noFinish) {
                        $('.picker__footer').css('display', 'none');
                        $('.picker__box').css('padding-bottom', '20px');
                    }
                }
            })
        });

        $("audio").on("play", function () {
            console.log("audio plays!");
            let that = this;
            $("audio").each(() => {
                if ($(this).get(0) !== $(that).get(0)) {
                    $(this).get(0).pause();
                    $(this).get(0).currentTime = 0;
                }
            });
        });

        $('[data-initialize="hover-audio"]').each(function () {
            let $this = $(this);
            $this.on('mouseenter', function () {
                let $this = $(this);

                let $spinner = $this.find('.load');
                let $play    = $this.find('.play');
                let $pause   = $this.find('.pause');
                let $player  = $this.find('.ha-player');

                let playing      = false;
                let clickHandler = function (e) {
                    e.preventDefault();
                    $play.css('opacity', playing ? 1 : 0);
                    $pause.css('opacity', playing ? 0 : 1);

                    if (playing) {
                        $player[0].pause();
                    }
                    else {
                        $player[0].play();
                    }
                    playing = !playing;
                };
                $this.on('click', clickHandler);
                // $play.on('click', clickHandler);
                // $pause.on('click', clickHandler);

                $spinner.css('opacity', 1);
                $play.css('opacity', 0);
                $pause.css('opacity', 0);

                let $delayId = _.delay(function () {
                    $spinner.css('opacity', 0);
                    $pause.css('opacity', 1);
                    $play.click();
                    $delayId = null;
                }, 700);

                $this.on('mouseleave', function () {
                    if ($delayId) {
                        window.clearTimeout($delayId);
                    }
                    if (playing) {
                        $player[0].currentTime = 0;
                        $pause.click();
                    }
                });
            });
        });

        $('[data-submit="form"]').each(function () {
            let $this = $(this);
            let href  = $this.attr('href');
            $this.on('click', function (e) {
                e.preventDefault();
                let $form = $this.closest('form');
                $form.attr('action', href);
                $form.trigger('submit');
            });
        });

        jQuery.extend(jQuery.fn.pickadate.defaults, {weekdaysLetter: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']});
    });

    function createDivToRenderString(render_class, item) {
        let div_class = '';
        switch (render_class) {
            case 'production-type-image small':
                div_class = 'production-type-image small production-type-' + item.productionTypeName;
                break;
            default:
        }
        return `<div class='${div_class}'></div>`;
    }
}
