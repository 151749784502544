import * as _ from 'lodash';
import { watchers } from "../watchers";

export function production_modal() {
    console.log('production modal attached');

    $(document).ready(function () {
        watchers();

        var blockCounter = window.modalBlockCounter || 0;

        let blockEvent = false;

        $(`#production-block-template`).hide();

        $(document).on('click.blockEventSecondary', function (e) {
            if (e.target.hasAttribute('data-secondary-custom-close') && !blockEvent) {
                $(`#secondary-modal-content`).empty();
                $(document).trigger('close-secondary-modal-bool');
                $(document).off('click.blockEventSecondary');
            } else {
                e.stopPropagation();
            }
        });

        $(document).on('click', '[data-production-remove-block]', function (e) {
            e.preventDefault();
            let id = Number($(this).attr('data-production-remove-block'));
            $(`#production_block_${id}`).remove();
        });

        $('#add-production-block').on("click", () => {
            let $template = $('#production-block-template');
            let $clone    = $template.clone(true);

            $clone.attr('id', `production_block_${blockCounter}`);
            $clone.find('[data-production-remove-block]').attr('data-production-remove-block', blockCounter);
            $clone.find('[name=production-start]')
                .attr('name', `production_blocks[${blockCounter}][start]`)
                .attr('id', `production_start_${blockCounter}`)
                .attr('data-production-start', blockCounter)
                .addClass('datepicker');
            $clone.find('[for=production-start]').attr('for', `production_start_${blockCounter}`);
            $clone.find('[name=production-end]')
                .attr('name', `production_blocks[${blockCounter}][end]`)
                .attr('id', `production_end_${blockCounter}`)
                .addClass('datepicker');
            $clone.find('[for=production-end]').attr('for', `production_end_${blockCounter}`);
            $clone.show();

            $('#production-block-holder').append($clone);

            blockCounter++;
        });

        $(document).arrive('[data-production-start]', {
            existing: true
        }, function () {
            var id    = $(this).attr('data-production-start');
            let val   = $(`[name='production_blocks[${id}][start]']`).val();
            //noinspection ES6ModulesDependencies
            let start = moment(val, 'DD.MM.YYYY').toDate();
            $(`#production_end_${id}`).pickadate('set', {
                min: start
            });

            $(this).on('change', function () {
                let val   = $(`[name='production_blocks[${id}][start]']`).val();
                //noinspection ES6ModulesDependencies
                let start = moment(val, 'DD.MM.YYYY').toDate();
                $(`#production_end_${id}`).pickadate('set', {
                    min: start
                });
                _.defer(() => {
                    $(`#production_end_${id}`).trigger('click');
                });
            });
        });

        $(`#save-modal-production`).on('click', function () {
            let save_button = $(`#save-modal-production`);

            $(`#save_loader_modal_production`).show();
            $(`#modal-alert-production-div`).hide();
            $(`#modal-alert-production-text`).html('');

            let $title_original = $(`#title_original_modal`).val();
            if (!$title_original || $title_original === '') {
                $(`#modal-alert-production-div`).show();
                $(`#modal-alert-production-text`).html('Bitte den Originalnamen eintragen!');
                $(`#save_loader_modal_production`).hide();
                return;
            }

            let $title_german = $(`#title_german_modal`).val();
            if (!$title_german || $title_german === '') {
                $title_german = null;
            }

            let $title_working = $(`#title_working_modal`).val();
            if (!$title_working || $title_working === '') {
                $title_working = null;
            }

            let $hidden_until = $(`#hidden_until_modal`).val();
            if (!$hidden_until || $hidden_until === '') {
                $hidden_until = null;
            }

            let $no_internal = $(`#no_internal_modal`).val();
            if (!$no_internal || $no_internal === '') {
                $no_internal = null;
            }

            let $directors = $(`#directors_modal`).val();

            let $company_id = $(`#production_company_id_modal`).val();
            if (!$company_id || $company_id === '') {
                $(`#modal-alert-production-div`).show();
                $(`#modal-alert-production-text`).html('Wähle bitte eine Firma aus!');
                $(`#save_loader_modal_production`).hide();
                return;
            }

            let $production_type_id = $(`#production_type_id_modal`).val();

            let $is_series = 0;
            if ($(`#is_series_2_modal`).prop('checked')) {
                $is_series = 1;
            }

            let $blocks_modal = [];
            let $i;
            for ($i = 0; $i < blockCounter; $i++) {
                let startVal = $(`[name='production_blocks[${$i}][start]']`).val();
                let endVal = $(`[name='production_blocks[${$i}][end]']`).val();
                if ((startVal !== '' && startVal != null) && (endVal !== '' && endVal != null)) {
                    $blocks_modal[$i] = {
                        'start' : startVal,
                        'end' : endVal
                    };
                }
            }

            save_button.prop('disabled', true);
            blockEvent = true;

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + `production?modal=1`,
                    {
                        method: 'POST',
                        data: {
                            from_route: '',
                            title_original: $title_original,
                            title_german: $title_german,
                            title_working: $title_working,
                            hidden_until: $hidden_until,
                            no_internal: $no_internal,
                            directors: $directors,
                            company_id: $company_id,
                            production_type_id: $production_type_id,
                            is_series: $is_series,
                            blocks: $blocks_modal
                        },
                        success: function (data) {
                            $(document).trigger('close-secondary-modal-production-success', [data]);
                        },
                        error: function () {
                            $(`#modal-alert-production-div`).show();
                            $(`#modal-alert-production-text`).html('Speichern fehlgeschlagen.');
                        },
                        complete: function () {
                            save_button.prop('disabled', false);
                            $(`#save_loader_modal_production`).hide();
                            blockEvent = false;
                        }
                    }
                );
            }, 0);
        });
    });
}