import * as _ from "lodash";

export function calendar() {

    var moment = require('moment');

    let current_date_string = null;

    let current_date = null;
    current_date = moment();

    let current_view_string = 'month';

    function showCalendar() {

        $('#calendar_loader').show();

        //noinspection JSUnresolvedVariable
        let URI = '/api/' + (
            window.calendar_type == 'user' ? 'calendar_feed_user' : 'calendar_feed_production'
        );

        if (window.CHILD) {
            URI = '/kidito' + URI;
        }

        $('#calendar-box').fullCalendar(
            {
                defaultDate        : current_date,
                defaultView        : current_view_string,
                locale             : 'de',
                selectable         : false,
                navLinks           : true,
                eventLimit         : true,
                fixedWeekCount     : false,
                lazyFetching       : true,
                textEscape         : false,
                displayEventTime   : false,
                eventOrder         : "order,start",
                header             : {
                    left  : 'prev,next today',
                    center: 'title',
                    right : 'month,agendaWeek,listMonth',
                },
                eventSources       : [{
                    url    : URI,
                    cache  : true,
                    data   : {
                        user_id      : window.calendar_user_id || window.AUTH_USER.id,
                        production_id: window.calendar_production_id,
                    },
                    success: function (data) {
                        //console.log(data);
                    },
                    error  : function () {
                        console.log(arguments);
                    },
                }],
                eventRender        : function (event, element) {
                    //$('#calendar_loader').show();
                    let view = $('#calendar-box').fullCalendar('getView').name;

                    if (event.renderHTML) {
                        $(element).find('.fc-title').html(event.title);
                        $(element).find('.fc-list-item-title').html(event.title);
                    }

                    if (event.type === 'booking_single') {
                        if (view === 'month') {
                            return false;
                        }
                    }
                    else if (event.type === 'booking_aggregate') {
                        if (view !== 'month') {
                            return false;
                        }
                    }
                    else if (event.type === 'prebooking_aggregate' && view === 'listMonth') {
                        let title = event.title.replace(/(<([^>]+)>)/ig, "").trim();
                        $(element).find('.fc-list-item-title').text(title);

                        let iconType = event.subType === 'day' ? '7' : '3';
                        let description = event.subType === 'day' ? ' tagsüber' : ' abends';
                        let $icon = $("<i>").addClass('mdi').addClass(`mdi-brightness-${iconType}`);
                        let $text = $("<span>").text(description);
                        $(element).find('.fc-list-item-time').text("").append($icon).append($text);
                    }
                    else if (event.type === 'blocking_time_single' && view !== 'agendaWeek') {
                        return false;
                    }

                    if (event.sub) {
                        let $container = $("<div>");
                        event.sub = _.sortBy(event.sub, 'order');
                        let sub_elem_counter = 0;
                        for (let ev of event.sub) {
                            sub_elem_counter++;
                            let color = ev.color || event.color || 'black';
                            let $elem = $("<a>");
                            //noinspection ES6ModulesDependencies
                            ev.start = moment(ev.start);
                            //noinspection ES6ModulesDependencies
                            ev.end = moment(ev.end);

                            let $tr = $("<tr>");

                            if (event.type !== 'prebooking_aggregate') {
                                $tr.append(
                                    $("<td>").append(
                                        $("<small>").append(
                                            $("<b>")
                                                .text(`${ev.start.format('HH:mm')}-${ev.end.format(
                                                    'HH:mm')}`)
                                        )
                                    )
                                );
                            } else {
                                $tr.append(
                                    $("<td>") // empty cell because of css; first child is not wrapped; otherwise the prebooking content is cut off
                                )
                                if (!ev.rendered) {
                                    ev.title = '(' + sub_elem_counter + ') ' + ev.title;
                                }
                                ev.rendered = true;
                            }
                            $tr.append(
                                $("<td>").append(
                                    $("<span>").html(`${ev.title}`)
                                )
                            );
                            $elem.css('background-color', color)
                                .attr('href', ev.url)
                                .append(
                                    $("<table>").append($tr)
                                );
                            $container.append($elem);
                        }

                        $(element).qtip(
                            {
                                content : {
                                    title: event.title + ' am ' + event.start.format('DD.MM.YYYY'),
                                    text : $container,
                                },
                                show    : {
                                    event: 'click mouseover',
                                    delay: 300,
                                },
                                style   : {
                                    classes: 'qtip-light calendar-tooltip z-index-1',
                                },
                                hide    : {
                                    fixed: true,
                                    delay: 300,
                                },
                                position: {
                                    container: $('body'),
                                    my       : 'top center',
                                    at       : 'bottom center',
                                },
                            }
                        ).css('cursor', 'pointer');
                    }
                },
                eventAfterAllRender: function (view) {
                    $('#calendar_loader').hide();
                    if (current_date !== null) {
                        //$('#calendar-box').fullCalendar('gotoDate', current_date);
                    }
                },
                viewRender         : function (view, element) {
                    current_date = view.calendar.currentDate;
                    current_date_string = view.calendar.currentDate.format('YYYY-MM-DD');
                    current_view_string = view.name;
                },
            }
        );
    }

    console.log("[calendar] running");
    $(document).ready(function () {

        if (window.calendar_type != 'production') {
            showCalendar();
        }
        else {
            console.log("production calendar");
            $('#calendar_loader').show();
            $("[data-initialize='qtip']").each(function () {
                let target = $(this).attr('data-qtip-target');
                let title = $(this).attr('data-title');
                $(this).qtip(
                    {
                        prerender: true,
                        content  : {
                            title: title,
                            text : $(target)/*.clone()*/,
                        },
                        show     : {
                            event: 'click mouseover',
                            delay: 300,
                        },
                        style    : {
                            classes: 'qtip-light calendar-tooltip z-index-1',
                        },
                        hide     : {
                            fixed: true,
                            delay: 300,
                        },
                        position : {
                            container: $('body'),
                            my       : 'top center',
                            at       : 'bottom center',
                        },
                    }
                )
            });
            $('#calendar_loader').hide();
        }

        let modalShowExisting = false;

        var stealthyRequire = require('stealthy-require');

        let MicroModal = stealthyRequire(arguments[5], function () {
            return require('micromodal');
        });

        MicroModal.init({
            disableScroll: false,
        });

        $(document).on('close-modal-bool', function () {
            modalShowExisting = false;
            $(`#modal-holder`).hide();
        });

        $(document).on('close-modal-success', function (event, $prebooking) {
            MicroModal.close('modal-holder');
            $(`#modal-content`).empty();
            $(`#modal-holder`).hide();
            modalShowExisting = false;
            $(`#dynamic-alert-success`).show();
            setTimeout(function () {
                $(`#dynamic-alert-success`).hide();
            }, 3000);
            $('#calendar-box').fullCalendar('destroy');
            showCalendar();
        });

        $(`#add-prebooking`).on('click', function () {
            if (!modalShowExisting) {
                modalShowExisting = true;
                $(`#add-prebooking`).prop('disabled', true);
                $(`#new_prebooking_loader`).show();
                let $user_id = document.getElementsByName('user_id')[0].value;
                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + 'prebooking/-1/edit?modal=1',
                        {
                            method  : 'GET',
                            success : function (data) {
                                $(`#modal-content`).empty();
                                $(`#modal-content`).append(data);
                                $(`#modal-holder`).show();
                                //let html = $.parseHTML(data);
                                MicroModal.show('modal-holder', {
                                    debugMode          : false,
                                    disableScroll      : true,
                                    closeTrigger       : 'data-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error   : function () {
                                console.log('Error loading modal');
                            },
                            complete: function () {
                                $(`#new_prebooking_loader`).hide();
                                $(`#add-prebooking`).prop('disabled', false);
                            }
                        }
                    );
                }, 0);
            }
        });
    });
}
