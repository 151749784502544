import * as _ from 'lodash';
import { watchers } from "../watchers";

export function user_modal() {
    console.log('user modal attached');

    $(document).ready(function () {
        watchers();

        let blockEvent = false;

        $(document).on('click.blockEventSecondary', function (e) {
            if (e.target.hasAttribute('data-secondary-custom-close') && !blockEvent) {
                $(`#secondary-modal-content`).empty();
                $(document).trigger('close-secondary-modal-bool');
                $(document).off('click.blockEventSecondary');
            } else {
                e.stopPropagation();
            }
        });

        $(`#save-modal-user`).on('click', function () {
            let save_button = $(`#save-modal-user`);

            $(`#save_loader_modal_user`).show();
            $(`#modal-alert-user-div`).hide();
            $(`#modal-alert-user-text`).html('');

            let $type = document.getElementsByName('type')[0].value;

            let $prename = $(`#prename_modal`).val();
            if (!$prename || $prename === '') {
                $(`#modal-alert-user-div`).show();
                $(`#modal-alert-user-text`).html('Bitte einen Vornamen eintragen!');
                $(`#save_loader_modal_user`).hide();
                return;
            }

            let $surname = $(`#surname_modal`).val();
            if (!$surname || $surname === '') {
                $(`#modal-alert-user-div`).show();
                $(`#modal-alert-user-text`).html('Bitte einen Nachnamen eintragen!');
                $(`#save_loader_modal_user`).hide();
                return;
            }

            let $email = $(`#email_modal`).val();
            if (!$email || $email === '') {
                $(`#modal-alert-user-div`).show();
                $(`#modal-alert-user-text`).html('Bitte eine E-mail-Adresse eintragen!');
                $(`#save_loader_modal_user`).hide();
                return;
            }


            let $mobile = $(`#mobile_modal`).val();
            let $phone = $(`#phone_modal`).val();
            if ($type === 'voice_actor') {
                if (!$mobile || $mobile === '') {
                    $(`#modal-alert-user-div`).show();
                    $(`#modal-alert-user-text`).html('Bitte eine Mobilfunknummer eintragen!');
                    $(`#save_loader_modal_user`).hide();
                    return;
                }
            } else {
                if (!$phone || $phone === '') {
                    $(`#modal-alert-user-div`).show();
                    $(`#modal-alert-user-text`).html('Bitte eine Telefonnummer eintragen!');
                    $(`#save_loader_modal_user`).hide();
                    return;
                }
            }

            save_button.prop('disabled', true);
            blockEvent = true;

            let $company_id = $(`#dispatchers_modal`).val();
            if (!$company_id || $company_id === '') {
                $company_id = null;
            }

            let $is_child = $(`#is_child_modal`).prop('checked');
            if (!$is_child || $is_child === '') {
                $is_child = null;
            }

            let $street = $(`#street_modal`).val();

            let $street_no = $(`#street_no_modal`).val();

            let $zip = $(`#zip_modal`).val();

            let $city = $(`#city_modal`).val();

            let $personal_data = {
                'prename': $prename,
                'surname': $surname
            };

            let $address = {
                'email': $email,
                'mobile': $mobile,
                'phone': $phone,
                'street': $street,
                'street_no': $street_no,
                'zip': $zip,
                'city': $city
            };

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + `user?modal=1`,
                    {
                        method: 'POST',
                        data: {
                            personal_data: $personal_data,
                            address: $address,
                            type: $type,
                            is_child: $is_child
                        },
                        success: function (data) {
                            $(document).trigger('close-secondary-modal-user-success', [data]);
                        },
                        error: function (jqXHR, exception) {
                            $(`#modal-alert-user-div`).show();
                            let msg = 'Speichern fehlgeschlagen.';
                            if (jqXHR.status === 406) {
                                msg = 'E-mail-Adresse wird bereits genutzt.'
                            }
                            $(`#modal-alert-user-text`).html(msg);
                        },
                        complete: function () {
                            save_button.prop('disabled', false);
                            $(`#save_loader_modal_user`).hide();
                            blockEvent = false;
                        }
                    }
                );
            }, 0);
        });
    });
}