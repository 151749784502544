import * as _ from "lodash";
import { watchers } from '../watchers';

export function prebooking_delete_modal() {
    $(document).ready(function () {
        let delete_mode = window.delete_mode || 0;

        let prebooking_id = window.prebooking_id || 0;
        let production_id = window.production_id || 0;
        let role_id = window.role_id || 0;

        let title_span = $(`#modal-title-span`);

        if (delete_mode === '1') {
            title_span.html('Voranmeldung löschen am...');
        } else if (delete_mode === '2') {
            title_span.html('Voranmeldung löschen ab...');
        } else if (delete_mode === '3') {
            title_span.html('Voranmeldung löschen von / bis...');
            $(`#delete_from`).on('change', function () {
                let val = $(`#delete-from`).val();
                let start = moment(val, 'DD.MM.YYYY').toDate();

                $(`#delete_to`).pickadate('set', {
                    min: start
                });
                _.defer(function () {
                    $(`#delete_to`).trigger('click');
                });
            });
        } else {
            title_span.html('');
        }

        watchers();

        let blockEvent = false;

        $(document).on('click.blockEventPrimary', function (e) {
            if (e.target.hasAttribute('data-custom-close') && !blockEvent) {
                $(`#modal-content`).empty();
                $(document).trigger('close-modal-bool');
                $(document).off('click.blockEventPrimary');
            } else {
                e.stopPropagation();
            }
        });

        $('#save_modal').on('click', function () {
            let save_button = $(`#save_modal`);

            $(`#modal-alert-div`).hide();
            $(`#modal-alert-text`).html('');

            let at = null;
            let from = null;
            let to = null;

            let requestData = null;

            if (delete_mode === '1') {
                let val = $(`#delete_date`).val();
                if (val === '' || val == null) {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Gebe bitte ein Datum an!');
                    $(`#save_loader_modal`).hide();
                    return;
                }
                //noinspection ES6ModulesDependencies
                at = moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
                requestData = {
                    prebooking_id: prebooking_id,
                    at: at
                };
            } else if (delete_mode === '2') {
                let val = $(`#delete_date`).val();
                if (val === '' || val == null) {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Gib bitte ein Datum an!');
                    $(`#save_loader_modal`).hide();
                    return;
                }
                //noinspection ES6ModulesDependencies
                from = moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
                requestData = {
                    prebooking_id: prebooking_id,
                    from: from
                };
            } else {
                let valFrom = $(`#delete_from`).val();
                let valTo = $(`#delete_to`).val();
                if (valFrom === '' || valFrom == null) {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Gib bitte den Beginn des Zeitraums an!');
                    $(`#save_loader_modal`).hide();
                    return;
                }
                if (valTo === '' || valTo == null) {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Gib bitte das Ende des Zeitraums an!');
                    $(`#save_loader_modal`).hide();
                    return;
                }
                //noinspection ES6ModulesDependencies
                from = moment(valFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
                //noinspection ES6ModulesDependencies
                to = moment(valTo, 'DD.MM.YYYY').format('YYYY-MM-DD');
                requestData = {
                    prebooking_id: prebooking_id,
                    from: from,
                    to: to
                };
            }

            save_button.prop('disabled', true);
            $(`#save_loader_modal`).show();
            blockEvent = true;

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + `prebooking/post/delete_modal`,
                    {
                        method: 'POST',
                        data: requestData,
                        success: function (data) {
                            //signal.emit('close-modal-success', data);
                            $(document).trigger('close-modal-success', [data]);
                        },
                        error: function () {
                            $(`#modal-alert-div`).show();
                            $(`#modal-alert-text`).html('Speichern fehlgeschlagen.');
                        },
                        complete: function () {
                            save_button.prop('disabled', false);
                            $(`#save_loader_modal`).hide();
                            blockEvent = false;
                        }
                    }
                );
            }, 0);
        });
    });
}