import * as _ from "lodash";

export function external() {
    console.log("[external] running");
    $(document).ready(function () {
        $('[data-remove="external_booker"]').on('click', (e) => {
            e.preventDefault();
            let ref = $(e.currentTarget).attr('href');
            $('#modal-confirm-remove-external-booker').modal('open');
            $('#confirm-btn').off('click').on('click', () => {
                location.href = ref;
            });
        });
    });
}