import * as _ from "lodash";

export function user_edit() {
    console.log("[user_edit] running");
    $(document).ready(function () {

        $('#availability_data-end_time').on('mousedown', function (e) {
            e.preventDefault();
        });

        _.each(_.range(0, 7), function (i) {

            $(`#working_times_${i}_starting_time`).on('mousedown', function (e) {
                e.preventDefault();
            });

            $(`#working_times_${i}_end_time`).on('mousedown', function (e) {
                e.preventDefault();
            });

            var cb = function () {
                if ($(`#working_times_${i}_starting_time`).val() && $(`#working_times_${i}_end_time`).val()) {
                    $(`#working_times_${i}_is_working`).prop("checked", true);
                }
                else {
                    $(`#working_times_${i}_is_working`).prop("checked", true);
                }
            };
            $(`#working_times_${i}_starting_time`).off('change').on('change', cb);
            $(`#working_times_${i}_end_time`).off('change').on('change', cb);
        });
    });
}
