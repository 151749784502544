import * as _ from 'lodash';
import { watchers } from "../watchers";

export function role_modal() {
    console.log('role modal attached');

    $(document).ready(function () {
        watchers();

        let blockEvent = false;

        $(document).on('click.blockEventSecondary', function (e) {
            if (e.target.hasAttribute('data-secondary-custom-close') && !blockEvent) {
                $(`#secondary-modal-content`).empty();
                $(document).trigger('close-secondary-modal-bool');
                $(document).off('click.blockEventSecondary');
            } else {
                e.stopPropagation();
            }
        });

        $('#name_german').on('change', function () {
            let $name_orig = $('#name_orig');

            if ($name_orig.val().length === 0) {
                $name_orig.val($(this).val());
                let $name_orig_label = document.getElementById('name_orig_label');
                $name_orig_label.className += ' active';
            }
            M.updateTextFields();
        });

        $(`#save-modal-role`).on('click', function () {
            let save_button = $(`#save-modal-role`);

            $(`#save_loader_modal_role`).show();
            $(`#modal-alert-role-div`).hide();
            $(`#modal-alert-role-text`).html('');

            let $name_german = $(`#name_german`).val();
            if (!$name_german || $name_german === '') {
                /*
                $(`#modal-alert-role-div`).show();
                $(`#modal-alert-role-text`).html('Bitte einen deutschen Namen eintragen!');
                $(`#save_loader_modal_role`).hide();
                return;*/
            }

            let $name_orig = $(`#name_orig`).val();
            if (!$name_orig || $name_orig === '') {
                $(`#modal-alert-role-div`).show();
                $(`#modal-alert-role-text`).html('Bitte einen Originalnamen eintragen!');
                $(`#save_loader_modal_role`).hide();
                return;
            }

            let $role_scope = $(`#role_scope_id`).val();
            if (!$role_scope || $role_scope === '') {
                $(`#modal-alert-role-div`).show();
                $(`#modal-alert-role-text`).html('Bitte die Größe der Rolle eintragen!');
                $(`#save_loader_modal_role`).hide();
                return;
            }

            save_button.prop('disabled', true);
            blockEvent = true;

            let $actor = $(`#actor_id`).val();

            let $production_id = document.getElementsByName('production_id')[0].value;

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + `production/${$production_id}/role/modal`,
                    {
                        method: 'POST',
                        data: {
                            production_id: $production_id,
                            name: $name_german,
                            name_orig: $name_orig,
                            role_scope_id: $role_scope,
                            actor_id: $actor,
                        },
                        success: function (data) {
                            $(document).trigger('close-secondary-modal-role-success', [data]);
                        },
                        error: function () {
                            $(`#modal-alert-role-div`).show();
                            $(`#modal-alert-role-text`).html('Speichern fehlgeschlagen.');
                        },
                        complete: function () {
                            save_button.prop('disabled', false);
                            $(`#save_loader_modal_role`).hide();
                            blockEvent = false;
                        }
                    }
                );
            }, 0);
        });
    });
}