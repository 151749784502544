export function register() {
    window.CHILD = window.CHILD || false;
    console.log(`[register] running (CHILD: ${window.CHILD})`);
    $(document).ready(function () {
        $('#dispatcher,#booker,#production_manager').on('change', () => {
            let checked = $('#dispatcher,#booker,#production_manager').is(':checked');
            if (checked) {
                $('#employment-type-wrapper,#dob-wrapper').removeClass('slide-in');
                $('#license-type-wrapper,#company-name-wrapper').addClass('slide-in');
                $('#voice_actor,#external_company').removeAttr('checked').removeAttr('disabled');
                $('#license_type').removeAttr('required');

                let $val = $('input[name=employment_type]:checked').val();
                if ($val !== 'freelancer') {
                    $('#company-wrapper').removeClass('slide-in');

                    $('#link-tou').attr('href', `/pdf/Nutzungsbedingungen_Firmen.pdf`);
                }
                else {
                    $('#link-tou').attr('href', `/pdf/Nutzungsbedingungen.pdf`);
                }
                $('#link-dp').attr('href', `/pdf/Datenschutzerklaerung.pdf`);
            }
        }).trigger('change');
        $('#voice_actor').on('change', () => {
            let $elem   = $('#voice_actor');
            let checked = $elem.prop('checked');
            if (checked) {
                $('#company-wrapper,#company-name-wrapper,#employment-type-wrapper').addClass('slide-in');
                $('#dispatcher,#booker,#production_manager,#external_company')
                    .removeAttr('checked')
                    .removeAttr('disabled');

                if (window.CHILD) {
                    $('#license-type-wrapper').removeClass('slide-in');
                    $('#dob-wrapper').addClass('slide-in');
                    $('#license_type').attr('required', 'required');
                }

                $('#link-dp').attr('href', `/pdf/Datenschutzerklaerung_Kinderstimmen.pdf`);
            }
        }).trigger('change');
        $('#external_company').on('change', () => {
            let $elem   = $('#external_company');
            let checked = $elem.prop('checked');
            if (checked) {
                $('#employment_type_2').click(); // non-free
                $('#dob-wrapper,#company-name-wrapper').removeClass('slide-in');
                $('#employment-type-wrapper,#license-type-wrapper,#company-wrapper').addClass('slide-in');
                $('input[name=employment_type]').trigger('change');
                $('#dispatcher,#booker,#production_manager,#voice_actor').removeAttr('checked').removeAttr('disabled');
                $('#license_type').removeAttr('required');

                $('#link-tou').attr('href', `/pdf/Nutzungsbedingungen_Firmen.pdf`);
            }

            $('#link-dp').attr('href', `/pdf/Datenschutzerklaerung.pdf`);
        }).trigger('change');

        $('#license_type').on('change', () => {
            let val     = Number($('#license_type').val());
            let linkMap = {
                0: 'Nutzungsbedingungen',
                1: 'Nutzungsbedingungen_Basispaket',
                2: 'Nutzungsbedingungen_Komfortpaket',
                3: 'Nutzungsbedingungen_Premiumpaket',
            };
            $('#link-tou').attr('href', `/pdf/${linkMap[val]}.pdf`);
        }).trigger('change');

        $('input[name=employment_type]').on('change', () => {
            let $val = $('input[name=employment_type]:checked').val();
            if ($val === 'freelancer') {
                $('#company-wrapper').addClass('slide-in');
            }
            else {
                $('#company-wrapper').removeClass('slide-in');
            }
        });
    });

    $('#date_of_birth').on('mousedown', function (e) {
        e.preventDefault();
    })
}
