export function role() {
    console.log("[role] running");
    $(document).ready(function () {
        $('#name').on('change', function () {
            let $name_orig = $('#name_orig');

            if ($name_orig.val().length === 0) {
                $name_orig.val($(this).val());
                let $name_orig_label = document.getElementById('name_orig_label');
                $name_orig_label.className += ' active';
            }
            M.updateTextFields();
        });
    });
}
