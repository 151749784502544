import * as _ from "lodash";
import {watchers} from '../watchers';

export function bookmark_user_modal() {
    console.log('modal attached');

    $(document).ready(function () {
        watchers();

        let user_show = window.user_show;
        let modalShow = false;
        let blockEvent = false;

        var stealthyRequire = require('stealthy-require');

        let MicroModal = stealthyRequire(arguments[5], function () {
            return require('micromodal');
        });
        MicroModal.init({
            disableScroll: false,
        });

        $(document).on('close-secondary-modal-bool', function () {
            modalShow = false;
            $('#secondary-modal-holder').hide();
        });

        $(document).on('close-secondary-modal-production-success', function (event, $production) {
            MicroModal.close('secondary-modal-holder');
            $(`#secondary-modal-content`).empty();
            $('#secondary-modal-holder').hide();
            modalShow = false;
            $(`#modal-alert-success-div`).show();
            $(`#modal-alert-success-text`).html('Speichern der Produktion erfolgreich.');

            var data = {
                id  : $production['id'],
                text: $production['best_title']
            };
            var newOption = new Option(data.text, data.id, true, true);
            $(`#production_id_modal`).append(newOption);
            //$(`#production_id_modal`).select2().trigger('select2:close');

            let $role_id_modal = $('#role_id_modal');
            $role_id_modal.empty();
            let $loader = $('#role_id_loader_modal');
            $loader.show();
            let $add_role = $('#add_role_modal');

            let $unknownOption = $('<option>')
                .val('')
                .attr('selected', true)
                .prop('disabled', true)
                .text('Bitte auswählen');
            $role_id_modal.append($unknownOption);

            $role_id_modal.attr('disabled', 'disabled');
            $add_role.attr('disabled', 'disabled');

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + 'api/roles',
                    {
                        method : 'GET',
                        data   : {
                            production_id: $production['id']
                        },
                        success: function (data) {
                            window.roles = _.sortBy(data, 'displayName');
                            _.chain(window.roles).filter({'production_id': $production['id']}).each(function (at) {
                                let $option = $('<option>').val(at.id).text(at.displayName);
                                $role_id_modal.append($option);
                            }).value();

                            // $role_id.material_select();
                            $loader.hide();
                            $role_id_modal.removeAttr('disabled');
                            $add_role.removeAttr('disabled');
                        },
                        error  : function () {
                            console.log(arguments);
                        }
                    }
                );
            }, 0);
        });

        $(document).on('close-secondary-modal-role-success', function (event, $role) {
            MicroModal.close('secondary-modal-holder');
            $(`#secondary-modal-content`).empty();
            $('#secondary-modal-holder').hide();
            modalShow = false;
            $(`#modal-alert-success-div`).show();
            $(`#modal-alert-success-text`).html('Speichern der Rolle erfolgreich.');
            $(`#role_id_modal`).append(`<option selected value="${$role['id']}">${$role['displayName']}</option>`);
        });

        $(document).on('click.blockEventPrimary', function (e) {
            if (e.target.hasAttribute('data-custom-close') && !blockEvent) {
                $(`#modal-content`).empty();
                $(document).trigger('close-modal-bool');
                $(document).off('click.blockEventPrimary');
            } else {
                e.stopPropagation();
            }
        });

        $("#production_id_modal").on('change', function () {
            let val = Number($(this).val());

            let $role_id_modal = $('#role_id_modal');
            $role_id_modal.empty();
            let $loader = $('#role_id_loader_modal');
            $loader.show();
            let $add_role = $('#add_role_modal');

            let $emptyOption = $('<option>')
                .val('')
                .attr('selected', true)
                .text('Bitte auswählen');
            $role_id_modal.append($emptyOption);

            $('#production_id_modal').on('select2:close', function () {
                $('#production_id_modal').off('select2:close');
                $role_id_modal.attr('disabled', 'disabled');
                $add_role.attr('disabled', 'disabled');

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + 'api/roles',
                        {
                            method : 'GET',
                            data   : {
                                production_id: val
                            },
                            success: function (data) {
                                //$role_id_modal.empty();
                                window.roles = _.sortBy(data, 'displayName');
                                _.chain(window.roles).filter({'production_id': val}).each(function (at) {
                                    let $option = $('<option>').val(at.id).text(at.displayName);
                                    $role_id_modal.append($option);
                                }).value();

                                // $role_id.material_select();
                                $loader.hide();
                                $role_id_modal.removeAttr('disabled');
                                $add_role.removeAttr('disabled');
                            },
                            error  : function () {
                                console.log(arguments);
                            }
                        }
                    );
                }, 0);
            });
        });

        $(`#add_production_modal`).on('click', function () {
            if (!modalShow) {
                modalShow = true;

                $(`#add_production_modal`).prop('disabled', true);
                $(`#add_role_modal`).prop('disabled', true);

                let modal_title_loader = $(`#modal_title_loader`);
                modal_title_loader.show();

                let url_string = window.API_BASE + `production/-1/edit?modal=1`;
                if (user_show) {
                    url_string = url_string + '&user_show=1';
                }

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        url_string,
                        {
                            method  : 'GET',
                            success : function (data) {
                                $(`#secondary-modal-content`).empty();
                                $(`#secondary-modal-content`).append(data);
                                $(`#secondary-modal-title-span`).html('Neue Produktion');
                                $('#secondary-modal-holder').show();
                                MicroModal.show('secondary-modal-holder', {
                                    debugMode          : false,
                                    disableScroll      : true,
                                    closeTrigger       : 'data-secondary-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error   : function () {
                                console.log('Error loading secondary modal');
                            },
                            complete: function () {
                                modal_title_loader.hide();
                                $(`#add_production_modal`).prop('disabled', false);
                                if ($("#production_id_modal").val() > 0) {
                                    $(`#add_role_modal`).prop('disabled', false);
                                }
                            }
                        }
                    );
                }, 0);
            }
        });

        $(`#add_role_modal`).on('click', function () {
            if (!modalShow) {
                modalShow = true;

                let $production_id = $(`#production_id_modal`).val();

                if (!$production_id || $production_id === '') {
                    $(`#modal-alert-div`).show();
                    $(`#modal-alert-text`).html('Wähle bitte eine Produktion für die Rolle aus!');
                    return;
                }

                $(`#add_production_modal`).prop('disabled', true);
                $(`#add_role_modal`).prop('disabled', true);

                let modal_title_loader = $(`#modal_title_loader`);
                modal_title_loader.show();

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + `production/${$production_id}/role/-1/edit?modal=1`,
                        {
                            method  : 'GET',
                            success : function (data) {
                                $(`#secondary-modal-content`).empty();
                                $(`#secondary-modal-content`).append(data);
                                $(`#secondary-modal-title-span`).html('Neue Rolle');
                                $('#secondary-modal-holder').show();
                                MicroModal.show('secondary-modal-holder', {
                                    debugMode          : false,
                                    disableScroll      : true,
                                    closeTrigger       : 'data-secondary-custom-close',
                                    awaitCloseAnimation: true
                                });
                            },
                            error   : function () {
                                console.log('Error loading secondary modal');
                            },
                            complete: function () {
                                modal_title_loader.hide();

                                $(`#add_production_modal`).prop('disabled', false);
                                if ($("#production_id_modal").val() > 0) {
                                    $(`#add_role_modal`).prop('disabled', false);
                                }
                            }
                        }
                    );
                }, 0);
            }
        });

        $(`#save_modal`).on('click', function () {

            let save_button = $(`#save_modal`);

            $(`#modal-alert-div`).hide();
            $(`#modal-alert-text`).html('');

            let $production_id = $(`#production_id_modal`).val();
            if (!$production_id || $production_id === '') {
                $(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Wähle bitte eine Produktion aus!');
                $(`#save_loader_modal`).hide();
                return;
            }


            let $role_id = $(`#role_id_modal`).val();
            if (!$role_id || $role_id === '') {
                $(`#modal-alert-div`).show();
                $(`#modal-alert-text`).html('Wähle bitte eine Rolle aus!');
                $(`#save_loader_modal`).hide();
                return;
            }

            let $user_id = document.getElementsByName('user_id')[0].value;

            save_button.prop('disabled', true);
            $(`#save_loader_modal`).show();

            _.delay(function () {
                //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                $.ajax(
                    window.API_BASE + `role/${$role_id}/user/${$user_id}/1?modal=1`,
                    {
                        method  : 'GET',
                        data    : {
                            production_id: $production_id,
                            role_id      : $role_id,
                            user_id      : $user_id,
                        },
                        success : function (data) {
                            if (parseInt(data) === 1) {
                                $(document).trigger('close-modal-success', [data]);
                            }
                            else if (parseInt(data) === 0) {
                                $(`#modal-alert-div`).show();
                                $(`#modal-alert-text`).html('Der/Die Sprecher/in ist der Rolle schon zugeordnet.');
                            }
                            else {
                                $(`#modal-alert-div`).show();
                                $(`#modal-alert-text`).html('Speichern fehlgeschlagen.');
                            }
                        },
                        error   : function () {
                            $(`#modal-alert-div`).show();
                            $(`#modal-alert-text`).html('Speichern fehlgeschlagen.');
                        },
                        complete: function () {
                            save_button.prop('disabled', false);
                            $(`#save_loader_modal`).hide();
                        }
                    }
                );
            }, 0);
        });
    });
}