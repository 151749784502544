import * as _ from "lodash";

export function booking() {
    console.log("[booking] running");
    $(document).ready(function () {
        window.ateliers = JSON.parse(window.ateliers);
        // window.roles    = JSON.parse(window.roles);

        $("#production_id").on('change', function () {
            let val = Number($(this).val());

            let $role_id = $('#role_id');
            $role_id.html("");
            let $loader = $('#role_id_loader');
            $loader.show();
            let $add_role = $('#add_role');

            let $emptyOption = $('<option>')
                .val('')
                .prop('disabled', true)
                .attr('selected', true)
                .text('bitte auswählen');
            $role_id.append($emptyOption);

            let $unknownOption = $('<option>')
            .val('-1')
             .prop('disabled', false)
             .attr('selected', false)
             .text('Nicht bekannt');
            $role_id.append($unknownOption);

            $('#production_id').on('select2:close', function () {
                $role_id.attr('disabled', 'disabled');
                $add_role.attr('disabled', 'disabled');

                _.delay(function () {
                    //noinspection ES6ModulesDependencies,JSUnresolvedVariable
                    $.ajax(
                        window.API_BASE + 'api/roles',
                        {
                            method:  'GET',
                            data:    {
                                production_id: val
                            },
                            success: function (data) {
                                window.roles = _.sortBy(data, 'displayName');
                                _.chain(window.roles).filter({'production_id': val}).each(function (at) {
                                    let $option = $('<option>').val(at.id).text(at.displayName);
                                    $role_id.append($option);
                                }).value();

                                // $role_id.material_select();
                                $loader.hide();
                                $role_id.removeAttr('disabled');
                                $add_role.removeAttr('disabled');
                            },
                            error:   function () {
                                console.log(arguments);
                            }
                        }
                    );
                }, 0);
            });
        });

        $("#company_id").on('change', function () {
            let id               = $(this).val();
            let $selected_option = $(this).find(`[value=${id}]`);
            let standard_studio  = Number($selected_option.data('standard-studio'));
            let standard_atelier = Number($selected_option.data('standard-atelier'));
            if (standard_studio > 0) {
                $('#studio_id').val(standard_studio).trigger('change');
                _.defer(() => {
                    $('#atelier_id').val(standard_atelier).trigger('change');
                });
            }
        });

        $("#studio_id").on('change', function () {
            let val = Number($(this).val());

            let $atelier_id = $('#atelier_id');
            $atelier_id.html("");

            let $emptyOption = $('<option>')
                .val('')
                .prop('disabled', true)
                .attr('selected', true)
                .text('bitte auswählen');
            $atelier_id.append($emptyOption);

            _.chain(window.ateliers).filter({'studio_id': val}).each(function (at) {
                let $option = $('<option>').val(at.id).text(at.name);
                $atelier_id.append($option);
            }).value();

            // $atelier_id.material_select();
        });

        $('#day').on('change', () => {
            $('#start').click();
        });

        $('#start').on('change', function () {
            //noinspection ES6ModulesDependencies
            let time = moment($(this).val(), 'HH:mm');
            let end  = time.add(1, 'hour').format('HH:mm');
            $('#end').val(end).click();
        });

        $('#day, #start, #end').on('mousedown', function (e) {
            e.preventDefault();
        });
    });
}
