export function production() {
    console.log("[production] running");
    $(document).ready(function () {
        var blockCounter = window.blockCounter || 0;

        $('.datepicker').on('mousedown', function (e) {
            e.preventDefault();
        });

        $(document).on('click', '[data-remove-block]', function (e) {
            e.preventDefault();
            let id = Number($(this).attr('data-remove-block'));
            $(`#block_${id}`).remove();
        });

        $('#add-block').on("click", () => {
            let $template = $('#block-template');
            let $clone    = $template.clone(true);

            $clone.attr('id', `block_${blockCounter}`);
            $clone.find('[data-remove-block]').attr('data-remove-block', blockCounter);
            $clone.find('[name=start]')
                  .attr('name', `blocks[${blockCounter}][start]`)
                  .attr('id', `start_${blockCounter}`)
                  .attr('data-start', blockCounter)
                  .addClass('datepicker');
            $clone.find('[for=start]').attr('for', `start_${blockCounter}`);
            $clone.find('[name=end]')
                  .attr('name', `blocks[${blockCounter}][end]`)
                  .attr('id', `end_${blockCounter}`)
                  .addClass('datepicker');
            $clone.find('[for=end]').attr('for', `end_${blockCounter}`);
            blockCounter++;

            $('#block-holder').append($clone);
        });

        $(document).arrive('[data-start]', {
            existing: true
        }, function () {
            var id    = $(this).attr('data-start');
            let val   = $(`[name='blocks[${id}][start]']`).val();
            //noinspection ES6ModulesDependencies
            let start = moment(val, 'DD.MM.YYYY').toDate();
            $(`#end_${id}`).pickadate('set', {
                min: start
            });

            $(this).on('mousedown', function (e) {
                e.preventDefault();
            });

            $(`[name='blocks[${id}][end]`).on('mousedown', function (e) {
                e.preventDefault();
            });

            $(this).on('change', function () {
                let val   = $(`[name='blocks[${id}][start]']`).val();
                //noinspection ES6ModulesDependencies
                let start = moment(val, 'DD.MM.YYYY').toDate();
                $(`#end_${id}`).pickadate('set', {
                    min: start
                });
                console.log(`change: ${start}`);
                _.defer(() => {
                    $(`#end_${id}`).trigger('click');
                });
            });
        });
    });
}
